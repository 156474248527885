import { CONSTANTS } from '../config';

const dijkstra = (graph, start) => {
  const distances = [];

  for (let i = 0; i < graph.length; i++) distances[i] = Infinity;
  distances[start] = 0;

  const visited = [];

  while (true) {
    let shortestDistance = Infinity;
    let shortestIndex = -1;
    for (let i = 0; i < graph.length; i++) {
      if (distances[i] < shortestDistance && !visited[i]) {
        shortestDistance = distances[i];
        shortestIndex = i;
      }
    }

    if (shortestIndex === -1) {
      return distances;
    }

    for (let i = 0; i < graph[shortestIndex].length; i++) {
      if (
        graph[shortestIndex][i] !== 0
        && graph[shortestIndex][i] < CONSTANTS.MIN_DISTANCE
        && distances[shortestIndex] < CONSTANTS.MIN_DISTANCE
      ) {
        distances[i] = 1;
      }
    }

    visited[shortestIndex] = true;
  }
};

export default dijkstra;
