/* eslint-disable require-yield */
// flow
import { takeLatest, put, debounce } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { apiConfig } from '../../config';
import { post, sendDelete, put as sendPut } from '../../modules/api/request';
import {
  setCreateSectionFailure,
  MENU_BUILDER_ACTIONS,
  attemptToCreateDish,
  dropDishToOtherSectionSuccess,
  editModifierGroupSuccess
} from '../actions/menuBuilder';

import { attemptToGetSections, attemptToGetOrderSections } from '../actions/sections';
import sagaErrorHandler from '../../utils/sagaErrorHandler';
import { attemptToGetMenu, attemptToGetOrderingMenu } from '../actions/menus';

const { API_TOKEN, HANDLE_URL } = apiConfig;

function* createMenuFromPdf({ payload }) {
  const { dishGroups, menuId, changeRoute } = payload;
  const sections = dishGroups.map(({ sectionName, description }, i) => ({
    name: sectionName,
    description: description || '',
    sort_order: i
  }));
  try {
    const createdSections = yield post(`v1/web_menu/${menuId}/section/bulk/`, {
      sections
    });

    yield Promise.all(
      createdSections.data.map(({ id }, i) =>
        post(`v1/web_menu_section/${id}/dish/bulk/`, {
          dishes: dishGroups[i].dishList.map(({ dishName, dishDescription, dishPrice }, index) => ({
            name: (dishName || '').normalize('NFKD').replace(/[\u0300-\u036f]/g, ''),
            description: (dishDescription || '').normalize('NFKD').replace(/[\u0300-\u036f]/g, ''),
            price: dishPrice || ' ',
            sort_order: index
          }))
        }))
    );
    yield put(attemptToGetMenu({ id: menuId }));
    yield changeRoute(menuId);
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* createSection({ payload }) {
  const { sectionName, sectionDescription, id, sort_order, orderingMenu } = payload;

  const section = {
    name: sectionName,
    description: sectionDescription,
    ...(sort_order && { sort_order })
  };

  try {
    const result = yield post(`v1/${orderingMenu ? 'ordering' : 'web'}_menu/${id}/section/`, section);
    if (!orderingMenu) {
      yield put(
        attemptToCreateDish({
          menuId: id,
          id: result.data.id,
          sort_order: result.data.section_dishes.length,
          orderingMenu
        })
      );
    } else {
      yield put(attemptToGetOrderSections({ id }));
    }
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setCreateSectionFailure(error));
  }
}

function* createDish({ payload }) {
  const { id, menuId, sort_order } = payload;
  const dish = {
    name: '',
    description: '',
    price: ' ',
    sort_order
  };
  try {
    yield post(`v1/web_menu_section/${id}/dish/`, dish);
    yield put(attemptToGetSections({ id: menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setCreateSectionFailure(error));
  }
}

function* createOption({ payload }) {
  const { id, menuId } = payload;
  const option = {
    name: '',
    description: '',
    price: ' '
  };
  try {
    yield post(`v1/web_menu_section_dish/${id}/option/`, option);
    yield put(attemptToGetSections({ id: menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* uploadImage({ payload }) {
  const { data, id, menuId, orderingMenu } = payload;
  data.path = data.name;
  const img = new FormData();
  img.append('image', data);
  img.append('api_token', API_TOKEN);
  img.append('handler_url', HANDLE_URL);
  try {
    yield post(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section_dish/${id}/image/`, img, false);
    yield put(orderingMenu ? attemptToGetOrderSections({ id: menuId }) : attemptToGetSections({ id: menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* deleteImage({ payload }) {
  const { id, menuId, orderingMenu } = payload;
  try {
    yield post(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section_dish/${id}/image/`);
    yield put(orderingMenu ? attemptToGetOrderSections({ id: menuId }) : attemptToGetSections({ id: menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* deleteSection({ payload }) {
  const { id, menuId, orderingMenu } = payload;
  try {
    yield sendDelete(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section/${id}`);
    yield put(orderingMenu ? attemptToGetOrderSections({ id: menuId }) : attemptToGetSections({ id: menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editSection({ payload }) {
  const { id, name, sort_order, description, orderingMenu, hidden } = payload;
  const data = {
    ...(name != null ? { name } : {}),
    ...(description != null ? { description } : {}),
    ...(sort_order != null ? { sort_order } : {}),
    ...(hidden != null ? { hidden } : {})
  };
  try {
    yield sendPut(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section/${id}`, data);
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editItemsOrder({ payload }) {
  try {
    yield sendPut('v1/modifier_item/bulk/', { modifier_items: payload });
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editGroupsOrder({ payload }) {
  try {
    yield sendPut('v1/modifier_group/bulk/', { modifier_groups: payload });
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editSectionOrder({ payload }) {
  const { sortedSectionData, orderingMenu } = payload;
  const sortedSectionDataWithoutSynced = sortedSectionData.filter(section => section.name !== 'Synced Dishes');
  try {
    yield sendPut(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section/bulk/`, {
      sections: sortedSectionDataWithoutSynced
    });
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editDishOrder({ payload }) {
  const { sortedDishData, orderingMenu } = payload;
  try {
    yield sendPut(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section_dish/bulk/`, { dishes: sortedDishData });
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editDish({ payload }) {
  const { id, sort_order, orderingMenu } = payload;
  const prefix = orderingMenu ? 'ordering' : 'web';
  const key = Object.keys(payload)[0];
  // eslint-disable-next-line no-constant-condition
  const changedValue = key === 'hidden' || 'sold_out' ? payload[key] : payload[key] || ' ';
  try {
    yield sendPut(`v1/${prefix}_menu_section_dish/${id}`, {
      [key]: changedValue,
      sort_order
    });
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editOption({ payload }) {
  const { id } = payload;
  const key = Object.keys(payload)[0];
  try {
    yield sendPut(`v1/web_menu_section_dish_option/${id}`, {
      [key]: payload[key] || ' '
    });
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* deleteDish({ payload }) {
  const { id, menuId, orderingMenu } = payload;
  try {
    yield sendDelete(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section_dish/${id}`);
    yield put(orderingMenu ? attemptToGetOrderSections({ id: menuId }) : attemptToGetSections({ id: menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* deleteOption({ payload }) {
  const { id, menuId } = payload;
  try {
    yield sendDelete(`v1/web_menu_section_dish_option/${id}`);
    yield put(attemptToGetSections({ id: menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editModifierGroup({ payload }) {
  const { groupId, editedModifierGroup, editedModifierItems, dish_id, menuId } = payload;
  const isModifierGroup = Object.keys(editedModifierGroup).length;
  const isModifierItem = editedModifierItems?.length;
  const { hiddenAll } = editedModifierGroup;
  try {
    if (hiddenAll != null) {
      if (hiddenAll) {
        yield post(`v1/modifier_group/${groupId}/hide_all/`);
      } else {
        yield post(`v1/modifier_group/${groupId}/show_all/`);
      }
      delete editedModifierGroup.hiddenAll;
    }
    if (isModifierGroup) {
      yield sendPut(`v1/modifier_group/${groupId}`, { ...editedModifierGroup, dish_id });
    }
    if (isModifierItem) {
      yield sendPut('v1/modifier_item/bulk/', { modifier_items: editedModifierItems });
    }
    if (isModifierItem || isModifierGroup) {
      if (isModifierItem) yield put(attemptToGetOrderSections({ id: menuId }));
      yield put(attemptToGetOrderingMenu({ id: menuId }));
      yield put(editModifierGroupSuccess());
      TOAST.success('Information was successfully updated');
    }
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* dropDish({ payload }) {
  const { dishId, menuId, onDropSectionId, orderingMenu } = payload;
  try {
    yield sendPut(`v1/${orderingMenu ? 'ordering' : 'web'}_menu_section_dish/${dishId}`, {
      section_id: onDropSectionId
    });
    yield put(orderingMenu ? attemptToGetOrderSections({ id: menuId }) : attemptToGetSections({ id: menuId }));
    yield put(dropDishToOtherSectionSuccess());
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* menuBuilder<T>(): Iterable<T> {
  yield takeLatest(MENU_BUILDER_ACTIONS.ATTEMPT_TO_CREATE_SECTION, createSection);
  yield takeLatest(MENU_BUILDER_ACTIONS.DELETE_SECTION, deleteSection);
  yield takeLatest(MENU_BUILDER_ACTIONS.EDIT_SECTION, editSection);
  yield takeLatest(MENU_BUILDER_ACTIONS.EDIT_SECTION_ORDER, editSectionOrder);
  yield takeLatest(MENU_BUILDER_ACTIONS.EDIT_DISH_ORDER, editDishOrder);
  yield takeLatest(MENU_BUILDER_ACTIONS.EDIT_DISH, editDish);
  yield takeLatest(MENU_BUILDER_ACTIONS.ATTEMPT_TO_DROP_DISH, dropDish);
  yield takeLatest(MENU_BUILDER_ACTIONS.EDIT_OPTION, editOption);
  yield takeLatest(MENU_BUILDER_ACTIONS.ATTEMPT_TO_CREATE_DISH, createDish);
  yield takeLatest(MENU_BUILDER_ACTIONS.EDIT_ITEMS_ORDER, editItemsOrder);
  yield takeLatest(MENU_BUILDER_ACTIONS.EDIT_GROUPS_ORDER, editGroupsOrder);
  yield takeLatest(MENU_BUILDER_ACTIONS.CREATE_MENU_FROM_PDF, createMenuFromPdf);
  yield debounce(500, MENU_BUILDER_ACTIONS.EDIT_MODIFIER_GROUP, editModifierGroup);
  yield takeLatest(MENU_BUILDER_ACTIONS.ATTEMPT_TO_CREATE_OPTION, createOption);
  yield takeLatest(MENU_BUILDER_ACTIONS.ATTEMPT_TO_UPLOAD_IMAGE, uploadImage);
  yield takeLatest(MENU_BUILDER_ACTIONS.ATTEMPT_TO_REMOVE_IMAGE, deleteImage);
  yield takeLatest(MENU_BUILDER_ACTIONS.DELETE_DISH, deleteDish);
  yield takeLatest(MENU_BUILDER_ACTIONS.ATTEMPT_TO_REMOVE_OPTION, deleteOption);
}

export default menuBuilder;
