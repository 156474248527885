import type { BaseAction } from '../../common/types';

export const BANNER_ACTIONS = {
  ATTEMPT_TO_GET_BANNER_DATA: '@BANNER_ACTIONS/ATTEMPT_TO_GET_BANNER_DATA',
  SET_BANNER_DATA: '@BANNER_ACTIONS/SET_BANNER_DATA',
  GET_BANNER_DATA: '@BANNER_ACTIONS/GET_BANNER_DATA',
  DELETE_BANNER_DATA: '@BANNER_ACTIONS/DELETE_BANNER_DATA'
};

export const attemptToGetBanner = (payload: Object): BaseAction => ({
  type: BANNER_ACTIONS.ATTEMPT_TO_GET_BANNER_DATA,
  payload
});
export const getBannerData = (payload: Object): BaseAction => ({
  type: BANNER_ACTIONS.GET_BANNER_DATA,
  payload
});
export const setBannerData = (payload: Object): BaseAction => ({
  type: BANNER_ACTIONS.SET_BANNER_DATA,
  payload
});
export const deleteBannerData = (payload: Object): BaseAction => ({
  type: BANNER_ACTIONS.DELETE_BANNER_DATA,
  payload
});
