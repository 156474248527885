import { all } from 'redux-saga/effects';

import media from './media';
import onBoarding from './onBoarding';
import menuUploader from './menuUploader';
import auth from './auth';
import restaurants from './restaurants';
import signUp from './signUp';
import menuBuilder from './menuBuilder';
import sections from './sections';
import menus from './menus';
import user from './user';
import customization from './customization';
import googleFonts from './googleFonts';
import recoverAccount from './recoverAccount';
import category from './category';
import gmb from './gmb';
import banner from './banner';

export default function* rootSaga() {
  yield all([
    media(),
    onBoarding(),
    menuUploader(),
    restaurants(),
    signUp(),
    menuBuilder(),
    sections(),
    menus(),
    user(),
    googleFonts(),
    recoverAccount(),
    customization(),
    auth(),
    category(),
    gmb(),
    banner()
  ]);
}
