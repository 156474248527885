/* eslint-disable no-param-reassign */
const mergeNearestElementsByCoordinates = (data) => {
  for (let i = 0; i < data.length; i++) {
    const { sectionNameScore, dishNameScore, dishDescriptionScore, coordinates: { left: x, right } } = data[i];

    for (let j = 0; j < data.length; j++) {
      if (i !== j) {
        const { coordinates: { left: x1, right: right1 } } = data[j];

        if (
          (Math.abs(x - right1) > 0 && Math.abs(x - right1) < 10) ||
                    (Math.abs(right1 - x) > 0 && Math.abs(right1 - x) < 10)
        ) {
          if (data[i].text.trim().toLowerCase() == 'oz' && /^[0-9]+$/.test(data[j].text.trim())) {
            data[i].text = `${data[j].text?.trim()} ${data[i].text?.trim()}`;

            data[i].coordinates.right = Math.max(right, right1);
            data[i].coordinates.left = Math.min(x, x1);
            data[i].dishDescriptionScore = Math.max(dishDescriptionScore, data[j].dishDescriptionScore);
            data[i].dishNameScore = Math.max(dishNameScore, data[j].dishNameScore);
            data[i].sectionNameScore = Math.max(sectionNameScore, data[j].sectionNameScore);

            data.splice(j, 1);
            j--;
          }
        }
      }
    }
  }
};

export default mergeNearestElementsByCoordinates;
