// flow
export const apiConfig = {
  API_URL: process.env.REACT_APP_API_URL,
  URL_PREFIX: process.env.REACT_APP_URL_PREFIX,
  API_TOKEN: process.env.REACT_APP_API_TOKEN,
  AUTHORIZATION_TOKEN: process.env.REACT_APP_AUTHORIZATION_TOKEN,
  CATEGORIES_API_URL: process.env.REACT_APP_CATEGORIES_API_URL,
  CATEGORIES_API_TOKEN: process.env.REACT_APP_CATEGORIES_API_TOKEN,
  HANDLE_URL: process.env.REACT_APP_HANDLE_URL || 'https://brainstorm-east-2.s3.us-east-2.amazonaws.com/handlers.js',
  GOOGLE_OAUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_REDIRECT_URI: process.env.REACT_APP_GOOGLE_REDIRECT_URI || 'https://menu-staging.winendine.com'
};

export const CONSTANTS = {
  MIN_SCORE: 0.87,
  MIN_DISTANCE: 9,
  PRICE_END_OF_THE_STRING_REGEX: /[0-9](\s)?[.|$]?(\s)?$/,
  PRICE_REGEX: /^[|$]?\s?[0-9]+[/]?([0-9]?)(\/?)([0-9]?)(\.|\$)?(\s?)+$/,
  SPACE_OR_DOT_REGEX: /^[.\s]+$/
};

export default {
  apiConfig,
  CONSTANTS
};

export const MENUSYNC_API_CONFIG = {
  URL: process.env.REACT_APP_CATEGORIES_API_URL
};
