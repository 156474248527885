// @flow
import type { BaseAction } from '../../common/types';
import { ON_BOARDING_ACTIONS } from '../actions/onBoarding';
import { ACTION_STATUSES } from '../../utils/constants';

export type OnBoardingState = {
  details: Object,
  menuFileUpload: {
    status: ?string,
    error: ?string,
    data: Object
  }
};

const initialState = (): OnBoardingState => ({
  details: null,
  menuFileUpload: {
    status: null,
    error: null,
    data: null
  }
});

const SAVE_ON_BOARDING_DETAILS = (state: OnBoardingState, action: BaseAction) => ({
  ...state,
  details: action.payload
});

const ATTEMPT_TO_UPLOAD_MENU_FILE = (state: OnBoardingState) => ({
  ...state,
  menuFileUpload: {
    ...state.menuFileUpload,
    status: ACTION_STATUSES.PENDING
  }
});

const SET_UPLOAD_MENU_FILE_SUCCEED = (state: OnBoardingState, action: BaseAction) => ({
  ...state,
  menuFileUpload: {
    ...state.menuFileUpload,
    status: ACTION_STATUSES.SUCCEED,
    data: action.payload
  }
});

const SET_UPLOAD_MENU_FILE_FAILURE = (state: OnBoardingState, action: BaseAction) => ({
  ...state,
  menuFileUpload: {
    ...state.menuFileUpload,
    status: ACTION_STATUSES.FAILED,
    data: action.payload
  }
});

const RESET_ON_BOARDING_STATE = () => ({
  ...initialState()
});

export default (state: OnBoardingState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case ON_BOARDING_ACTIONS.SAVE_ON_BOARDING_DETAILS:
      return SAVE_ON_BOARDING_DETAILS(state, action);
    case ON_BOARDING_ACTIONS.ATTEMPT_TO_UPLOAD_MENU_FILE:
      return ATTEMPT_TO_UPLOAD_MENU_FILE(state);
    case ON_BOARDING_ACTIONS.SET_UPLOAD_MENU_FILE_SUCCEED:
      return SET_UPLOAD_MENU_FILE_SUCCEED(state, action);
    case ON_BOARDING_ACTIONS.SET_UPLOAD_MENU_FILE_FAILURE:
      return SET_UPLOAD_MENU_FILE_FAILURE(state, action);
    case ON_BOARDING_ACTIONS.RESET_ON_BOARDING_STATE:
      return RESET_ON_BOARDING_STATE();

    default:
      return state;
  }
};
