/* eslint-disable no-param-reassign */
import getDishPriceFromDescription from './get-dish-price-from-description';

const setDishWithEmptyNameScoreToZero = (dishList, selectedAreaData) => {
  dishList.forEach(dish => {
    getDishPriceFromDescription(dish);

    if (typeof dish.dishName === 'undefined') {
      selectedAreaData.forEach(item => {
        item.data.forEach(i => {
          const dishIds = dish.id?.trim()?.split(' ');

          if (dishIds?.length) {
            dishIds.forEach(id => {
              if (id == i.id) {
                i.sectionNameScore = 0;
                i.dishNameScore = 0;
                i.dishDescriptionScore = 0;
                i.dishPriceScore = 0;
              }
            });
          }
        });
      });
    }
  });
};

export default setDishWithEmptyNameScoreToZero;
