// flow
import type { BaseAction } from '../../common/types';

export const ACCOUNT_RECOVER_ACTIONS = {
  ATTEMPT_TO_SEND_EMAIL: '@ACCOUNT_RECOVER_ACTIONS/ATTEMPT_TO_SEND_EMAIL'
};

export const attemptToSendEmail = (payload: Object): BaseAction => ({
  type: ACCOUNT_RECOVER_ACTIONS.ATTEMPT_TO_SEND_EMAIL,
  payload
});
