// @flow

import { takeLatest, put } from 'redux-saga/effects';
import { get } from '../../modules/api/request';

import { ACTION_STATUSES } from '../../utils/constants';

import {
  MEDIA_ACTIONS,
  setMediaUploadError,
  setMediaUploadStatus,
  setMediaUploadedUrl
} from '../actions/media';
import sagaErrorHandler from '../../utils/sagaErrorHandler';
import uploadFile from '../../utils/uploadFile';

const AWS_S3_PREFIX = 'v1/s3/putUrl';

function* uploadToS3(file, url) {
  try {
    yield uploadFile(file, url);
    return true;
  } catch (err) {
    return false;
  }
}

function* attemptToUploadFile({ payload }) {
  // TODO refactor code improvement
  yield put(setMediaUploadStatus(ACTION_STATUSES.PENDING, payload.storeKey));
  try {
    // get signed url
    const { data: { putUrl, path } } = yield get(`${AWS_S3_PREFIX}`, { extension: payload.extension });
    // upload to s3
    const file = yield uploadToS3(payload.file, putUrl);
    if (!file) {
      yield put(setMediaUploadStatus(ACTION_STATUSES.FAILED, payload.storeKey));
      yield put(setMediaUploadError('FAILED_TO_UPLOAD_FILE'));
      return;
    }
    yield put(setMediaUploadedUrl(path, payload.storeKey));
    yield put(setMediaUploadStatus(ACTION_STATUSES.SUCCEED, payload.storeKey));
  } catch (err) {
    const error = sagaErrorHandler(err);
    yield put(setMediaUploadError(error.debug));
    yield put(setMediaUploadStatus(ACTION_STATUSES.FAILED, payload.storeKey));
  }
}

function* authSaga<T>(): Iterable<T> {
  yield takeLatest(MEDIA_ACTIONS.ATTEMPT_TO_UPLOAD_FILE, attemptToUploadFile);
}

export default authSaga;
