/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { v4 } from 'uuid';
import type { BaseAction } from '../../common/types';
import { MENU_UPLOADER_ACTIONS } from '../actions/menuUploader';
import capitalizeEveryWord from '../../utils/capitalizeEveryWord';

import {
  ACTION_STATUSES,
  SELECT_AREA_STEP
} from '../../utils/constants';

export type MenuUploaderState = {
  extractedMenu: {
    status: ?string,
    error: ?string,
    data: Object
  },
  menu: {
    status: ?string,
    error: ?string,
    data: Object
  },
  excludedData: Array<Object>,
  MIN_SCORE: number
};

const initialState = (): MenuUploaderState => ({
  extractedMenu: {
    status: null,
    error: null,
    data: {}
  },
  menu: {
    status: null,
    error: null,
    data: {}
  },
  excludedData: null,
  MIN_SCORE: 0.87
});

const SAVE_CURRENT_STEP_DATA = (state: MenuUploaderState, action: BaseAction) => {
  const {
    step, data, remove, add, stepName
  }: any = action.payload;

  if (step === SELECT_AREA_STEP) {
    let selectedAreas = [...state.extractedMenu.data[stepName] || []];
    if (remove) {
      selectedAreas = selectedAreas.filter((item, i) => i !== data);
    }
    if (add) {
      selectedAreas.push(data);
    }
    return {
      ...state,
      extractedMenu: {
        ...state.extractedMenu,
        data: {
          ...state.extractedMenu.data,
          [stepName]: selectedAreas
        }
      }
    };
  }

  const { MIN_SCORE } = state;

  return {
    ...state,
    extractedMenu: {
      ...state.extractedMenu,
      data: {
        ...state.extractedMenu.data,
        [stepName]: data
      }
    },
    MIN_SCORE
  };
};

const SAVE_EXTRACTED_MENU_DATA = (state: MenuUploaderState, action: BaseAction) => {
  const data = {};
  // eslint-disable-next-line no-restricted-syntax
  if (action.payload) {
    if (Object.values(action.payload).length) {
      Object.values(action.payload).forEach(item => {
        if (item.sectionName) item.sectionName = capitalizeEveryWord(item.sectionName?.trim());
        if (item?.dishList?.length) {
          item.dishList = item?.dishList.map(dishlist => ({ ...dishlist, dishName: capitalizeEveryWord(dishlist.dishName?.trim()) }));
        }
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const value of action.payload) {
      data[v4()] = value;
    }
  }
  return {
    ...state,
    menu: {
      ...state.menu,
      data
    }
  };
};

const SAVE_EXCLUDED_DATA = (state: MenuUploaderState, action: BaseAction) => ({
  ...state,
  excludedData: action.payload
});

const ATTEMPT_TO_PUBLISH_MENU = (state: MenuUploaderState) => ({
  ...state,
  menu: {
    ...state.menu,
    status: ACTION_STATUSES.PENDING
  }
});

const SET_PUBLISH_MENU_SUCCEED = (state: MenuUploaderState) => ({
  ...state,
  menu: {
    ...state.menu,
    status: ACTION_STATUSES.SUCCEED,
    data: null
  }
});

const SET_PUBLISH_MENU_FAILURE = (state: MenuUploaderState, action: BaseAction) => ({
  ...state,
  menu: {
    ...state.menu,
    status: ACTION_STATUSES.FAILED,
    error: action.payload
  }
});

const RESET_MENU_UPLOADER_STATE = () => ({
  ...initialState()
});

export default (state: MenuUploaderState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case MENU_UPLOADER_ACTIONS.SAVE_CURRENT_STEP_DATA:
      return SAVE_CURRENT_STEP_DATA(state, action);
    case MENU_UPLOADER_ACTIONS.SAVE_EXTRACTED_MENU_DATA:
      return SAVE_EXTRACTED_MENU_DATA(state, action);
    case MENU_UPLOADER_ACTIONS.SAVE_EXCLUDED_DATA:
      return SAVE_EXCLUDED_DATA(state, action);
    case MENU_UPLOADER_ACTIONS.ATTEMPT_TO_PUBLISH_MENU:
      return ATTEMPT_TO_PUBLISH_MENU(state);
    case MENU_UPLOADER_ACTIONS.SET_PUBLISH_MENU_SUCCEED:
      return SET_PUBLISH_MENU_SUCCEED(state);
    case MENU_UPLOADER_ACTIONS.SET_PUBLISH_MENU_FAILURE:
      return SET_PUBLISH_MENU_FAILURE(state, action);
    case MENU_UPLOADER_ACTIONS.RESET_MENU_UPLOADER_STATE:
      return RESET_MENU_UPLOADER_STATE();

    default:
      return state;
  }
};
