import { CONSTANTS } from '../../config';

const addPriceScore = (score, fontFamily, elementFontFamily, fontSize, elementFontSize, data) => {
  let familyScore = 0;
  let sizeScore = 0;
  let priceScore = 0;

  if (fontFamily === elementFontFamily && fontSize === elementFontSize) familyScore = 1;
  sizeScore = (Math.min(fontSize, elementFontSize) / Math.max(fontSize, elementFontSize));

  priceScore = CONSTANTS.PRICE_REGEX.test(data.text) ? 1 : 0;
  // eslint-disable-next-line no-param-reassign
  data[score] = (familyScore + sizeScore + priceScore) / 3;
};

export default addPriceScore;
