const getPointsFromElement = (y, x, h, w) => {
  const n = 8;

  const arrX = [];
  const arrY = [];

  for (let i = 0; i <= n; i++) {
    arrX.push(x + (i * w) / n);
  }

  for (let i = 0; i <= n; i++) {
    arrY.push(y + (i * h) / n);
  }

  const result = [];

  for (let i = 0; i < arrX.length; i++) {
    for (let j = 0; j < arrY.length; j++) {
      result.push({ x: arrX[i], y: arrY[j] });
    }
  }

  return result;
};

export default getPointsFromElement;
