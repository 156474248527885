import type { BaseAction } from '../../common/types';

export const GMB_ACTIONS = {
  ATTEMPT_TO_SIGN_IN_GMB: '@AUTH/ATTEMPT_TO_SIGN_IN_GMB',
  ATTEMPT_TO_SIGN_IN_GMB_SUCCESS: '@ATTEMPT_TO_SIGN_IN_GMB_SUCCESS',
  ATTEMPT_TO_GET_GMB_ACCOUNTS: '@ATTEMPT_TO_GET_GMB_ACCOUNTS',
  GET_GMB_ACCOUNTS_SUCCESS: '@GET_GMB_ACCOUNTS_SUCCESS',
  ATTEMPT_TO_GET_GMB_LOCATIONS: '@ATTEMPT_TO_GET_GMB_LOCATIONS',
  GET_GMB_LOCATIONS_SUCCESS: '@GET_GMB_LOCATIONS_SUCCESS',
  SYNC_MENU_TO_GMB: '@SYNC_MENU_TO_GMB',
  SYNC_MENU_TO_GMB_SUCCESS: '@SYNC_MENU_TO_GMB_SUCCESS',
  SYNC_SINGLE_MENU_TO_GMB: '@AUTH/SYNC_SINGLE_MENU_TO_GMB'
};
export const attemptToSignInGMB = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.ATTEMPT_TO_SIGN_IN_GMB,
  payload
});
export const attemptToSignInGMBSuccess = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.ATTEMPT_TO_SIGN_IN_GMB_SUCCESS,
  payload
});
export const attemptToGetGMBAccounts = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.ATTEMPT_TO_GET_GMB_ACCOUNTS,
  payload
});
export const getGMBAccountsSuccess = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.GET_GMB_ACCOUNTS_SUCCESS,
  payload
});
export const attemptToGetGMBLocations = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.ATTEMPT_TO_GET_GMB_LOCATIONS,
  payload
});
export const getGMBLocationsSuccess = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.GET_GMB_LOCATIONS_SUCCESS,
  payload
});
export const syncMenuToGMB = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.SYNC_MENU_TO_GMB,
  payload
});
export const syncMenuToGMBSuccess = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.SYNC_MENU_TO_GMB_SUCCESS,
  payload
});
export const syncSingleMenuToGMB = (payload: Object): BaseAction => ({
  type: GMB_ACTIONS.SYNC_SINGLE_MENU_TO_GMB,
  payload
});
