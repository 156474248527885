// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { MENU_ACTIONS } from '../actions/menus';

export type MenusState = {
  status: ?string,
  error: ?string,
  data: { orderingMenu: any, data: any },
  modifierGroups: Array<any>,
  menu: any,
  menuId: number | string,
  orderingMenu: any,
  onlineMenu: any,
  menuList: any,
  name: any
};

const initialState = (): MenusState => ({
  status: null,
  error: null,
  data: {},
  modifierGroups: [],
  menu: null,
  menuId: '',
  orderingMenu: null,
  onlineMenu: null,
  menuList: null,
  name: null
});

const ATTEMPT_TO_GET_ONLINE_MENUS = (state: MenusState) => {
  return {
    ...state,
    status: ACTION_STATUSES.PENDING
  };
};
const ATTEMPT_TO_GET_ALL_ORDERING_MENUS = (state: MenusState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

const SET_MENUS_STATUS = (state: MenusState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

const ATTEMPT_TO_DELETE_MENU = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.PENDING,
  menuId: action?.payload?.menuId
});
const ATTEMPT_TO_UPDATE_MENU = (state: MenusState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});
const DELETE_MENU_SUCCESS = (state: MenusState, action: BaseAction) => ({
  ...state,
  [action.payload?.orderingMenu ? 'orderingMenu' : 'onlineMenu']: action.payload?.data,
  status: ACTION_STATUSES.SUCCEED
});

// eslint-disable-next-line no-unused-vars
const SET_GET_MENUS_SUCCEED = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  orderingMenu: action.payload?.orderingMenu || state.orderingMenu,
  onlineMenu: action.payload?.onlineMenu || state.onlineMenu,
  menuList: action.payload?.menuList || state.menuList,
  name: action.payload?.name,
  modifierGroups: action.payload
});
const CLEAR_ALL_MENUS = (state: MenusState) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  orderingMenu: null,
  onlineMenu: null,
  menuList: null,
  name: null
});
const SET_GET_MENU_SUCCEED = (state: MenusState, action: BaseAction) => {
  return {
    ...state,
    status: ACTION_STATUSES.SUCCEED,
    menu: action.payload
  };
};

const SET_CREATE_MENU_SUCCEED = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  menu: action.payload
});

const SET_GET_MENUS_FAILURE = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});

const SET_GET_MENU_FAILURE = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});

const SET_CREATE_MENU_FAILURE = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});
const HIDE_PUBLISH_SUCCESS = (state: MenusState, action: BaseAction) => {
  const key = action.payload?.online ? 'onlineMenu' : 'orderingMenu';
  state[key].menus.forEach((i) => {
    // $FlowFixMe
    if (i.id === action.payload.id) {
      // $FlowFixMe
      // eslint-disable-next-line no-param-reassign
      i.hidden = action.payload?.hidden;
    }
  });
  return {
    ...state,
    action: MENU_ACTIONS.HIDE_PUBLISH_SUCCESS,
    [key]: state[key],
    status: ACTION_STATUSES.SUCCEED
  };
};

export default (state: MenusState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case MENU_ACTIONS.ATTEMPT_TO_GET_ONLINE_MENUS:
      return ATTEMPT_TO_GET_ONLINE_MENUS(state);
    case MENU_ACTIONS.ATTEMPT_TO_GET_ALL_ORDERING_MENUS:
      return ATTEMPT_TO_GET_ALL_ORDERING_MENUS(state);
    case MENU_ACTIONS.CLEAR_ALL_MENUS:
      return CLEAR_ALL_MENUS(state);
    case MENU_ACTIONS.ATTEMPT_TO_UPDATE_MENU:
      return ATTEMPT_TO_UPDATE_MENU(state);
    case MENU_ACTIONS.SET_GET_MENUS_SUCCEED:
      return SET_GET_MENUS_SUCCEED(state, action);
    case MENU_ACTIONS.SET_CREATE_MENU_SUCCEED:
      return SET_CREATE_MENU_SUCCEED(state, action);
    case MENU_ACTIONS.SET_GET_MENU_SUCCEED:
      return SET_GET_MENU_SUCCEED(state, action);
    case MENU_ACTIONS.SET_GET_MENUS_FAILURE:
      return SET_GET_MENUS_FAILURE(state, action);
    case MENU_ACTIONS.SET_CREATE_MENU_FAILURE:
      return SET_CREATE_MENU_FAILURE(state, action);
    case MENU_ACTIONS.SET_GET_MENU_FAILURE:
      return SET_GET_MENU_FAILURE(state, action);
    case MENU_ACTIONS.SET_MENUS_STATUS:
      return SET_MENUS_STATUS(state);
    case MENU_ACTIONS.ATTEMPT_TO_DELETE_MENU:
      return ATTEMPT_TO_DELETE_MENU(state, action);
    case MENU_ACTIONS.DELETE_MENU_SUCCESS:
      return DELETE_MENU_SUCCESS(state, action);
    case MENU_ACTIONS.HIDE_PUBLISH_SUCCESS:
      return HIDE_PUBLISH_SUCCESS(state, action);
    default:
      return state;
  }
};
