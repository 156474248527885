// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { MENU_BUILDER_ACTIONS } from '../actions/menuBuilder';

export type menuBuilder = {
  modifierStatus: ?string,
  status: ?string,
  error: ?string,
  data: ?any
};

const initialState = (): menuBuilder => ({
  modifierStatus: null,
  status: null,
  error: null,
  data: []
});

// eslint-disable-next-line no-unused-vars
const SET_CREATE_SECTION_SUCCEED = (state: menuBuilder, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  data: action.payload
});

const SET_CREATE_SECTION_FAILURE = (state: menuBuilder, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});

const ATTEMPT_TO_DROP_DISH = (state: menuBuilder) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

const EDIT_MODIFIER_GROUP = (state: menuBuilder) => ({
  ...state,
  modifierStatus: ACTION_STATUSES.PENDING
});

const EDIT_MODIFIER_GROUP_SUCCESS = (state: menuBuilder) => ({
  ...state,
  modifierStatus: ACTION_STATUSES.SUCCEED
});

const DROP_DISH_SUCCESS = (state: menuBuilder) => {
  return {
    ...state,
    status: ACTION_STATUSES.SUCCEED
  };
};

export default (state: menuBuilder = initialState(), action: BaseAction) => {
  switch (action.type) {
    case MENU_BUILDER_ACTIONS.SET_CREATE_SECTION_SUCCEED:
      return SET_CREATE_SECTION_SUCCEED(state, action);
    case MENU_BUILDER_ACTIONS.SET_CREATE_SECTION_FAILURE:
      return SET_CREATE_SECTION_FAILURE(state, action);
    case MENU_BUILDER_ACTIONS.ATTEMPT_TO_DROP_DISH:
      return ATTEMPT_TO_DROP_DISH(state);
    case MENU_BUILDER_ACTIONS.DROP_DISH_SUCCESS:
      return DROP_DISH_SUCCESS(state);
    case MENU_BUILDER_ACTIONS.EDIT_MODIFIER_GROUP:
      return EDIT_MODIFIER_GROUP(state);
    case MENU_BUILDER_ACTIONS.EDIT_MODIFIER_GROUP_SUCCESS:
      return EDIT_MODIFIER_GROUP_SUCCESS(state);
    default:
      return state;
  }
};
