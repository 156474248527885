// @flow
/**
 * redirect to home page
 * @returns {Undefined} -
 */
const redirectToHomePage = () => {
  window.location.href = '/';
};

export default redirectToHomePage;
