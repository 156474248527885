import type { BaseAction } from '../../common/types';

export const MENU_UPLOADER_ACTIONS = {
  RESET_MENU_UPLOADER_STATE: '@MENU_UPLOADER/RESET_MENU_UPLOADER_STATE',
  SAVE_SELECTED_AREA_DATA: 'SAVE_SELECTED_AREA_DATA',
  SAVE_CURRENT_STEP_DATA: '@MENU_UPLOADER/SAVE_CURRENT_STEP_DATA',
  SAVE_EXTRACTED_MENU_DATA: '@MENU_UPLOADER/SAVE_EXTRACTED_MENU_DATA',
  SAVE_EXCLUDED_DATA: '@MENU_UPLOADER/SAVE_EXCLUDED_DATA',

  ATTEMPT_TO_PUBLISH_MENU: '@MENU_UPLOADER/ATTEMPT_TO_PUBLISH_MENU',
  SET_PUBLISH_MENU_SUCCEED: '@MENU_UPLOADER/SET_PUBLISH_MENU_SUCCEED',
  SET_PUBLISH_MENU_FAILURE: '@MENU_UPLOADER/SET_PUBLISH_MENU_FAILURE'
};

export const saveSelectedAreaData = (payload: Object): BaseAction => {
  return {
    type: MENU_UPLOADER_ACTIONS.SAVE_SELECTED_AREA_DATA,
    payload
  };
};

export const saveCurrentStepData = (payload: Object): BaseAction => ({
  type: MENU_UPLOADER_ACTIONS.SAVE_CURRENT_STEP_DATA,
  payload
});

export const attemptToPublishMenu = (payload: Object): BaseAction => ({
  type: MENU_UPLOADER_ACTIONS.ATTEMPT_TO_PUBLISH_MENU,
  payload
});

export const setPublishMenuSucceed = (): BaseAction => ({
  type: MENU_UPLOADER_ACTIONS.SET_PUBLISH_MENU_SUCCEED
});

export const setPublishMenuFailure = (payload: Object): BaseAction => ({
  type: MENU_UPLOADER_ACTIONS.SET_PUBLISH_MENU_FAILURE,
  payload
});

export const resetMenuUploaderState = () => ({
  type: MENU_UPLOADER_ACTIONS.RESET_MENU_UPLOADER_STATE
});

export const saveExtractedMenuData = (payload: Object): BaseAction => ({
  type: MENU_UPLOADER_ACTIONS.SAVE_EXTRACTED_MENU_DATA,
  payload
});

export const saveExcludedData = (payload: Object): BaseAction => ({
  type: MENU_UPLOADER_ACTIONS.SAVE_EXCLUDED_DATA,
  payload
});
