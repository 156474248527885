import type { BaseAction } from '../../common/types';
import { CATEGORY_ACTIONS } from '../actions/category';
import { ACTION_STATUSES } from '../../utils/constants';

export type CategoryState = {
  status: ?string,
  error: ?string,
  data: [{
    id: string,
    menuName: string,
    barId: string,
    lastSyncedDate: string
  }],
};

const initialState = (): CategoryState => ({
  status: null,
  error: null,
  data: []
});

const ATTEMPT_TO_GET_CATEGORIES = (state: CategoryState) => {
  return {
    ...state
  };
};

const SET_GET_CATEGORIES_PENDING = (state: CategoryState) => {
  return {
    ...state,
    status: ACTION_STATUSES.PENDING
  };
};

const SET_GET_CATEGORIES_SUCCEED = (state: CategoryState, action: BaseAction) => {
  return {
    ...state,
    data: action.payload.data,
    total: action.payload.total,
    easyTabRestName: action.payload.easyTabRestName,
    status: ACTION_STATUSES.SUCCEED
  };
};

const SET_SYNC_MENU_ITEM_PENDING = (state: CategoryState, action: BaseAction) => {
  return {
    ...state,
    menuItemStatus: ACTION_STATUSES.PENDING,
    menu_login_id:action.payload?.login_id,
  };
};

const SET_SYNC_MENU_ITEM_SUCCEED = (state: CategoryState) => {
  return {
    ...state,
    menuItemStatus: ACTION_STATUSES.SUCCEED
  };
};

const SET_SYNC_MENU_ITEM_FAILURE = (state: CategoryState) => {
  return {
    ...state,
    menuItemStatus: ACTION_STATUSES.FAILED
  };
};

export default (state: CategoryState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case CATEGORY_ACTIONS.ATTEMPT_TO_GET_CATEGORIES:
      return ATTEMPT_TO_GET_CATEGORIES(state);
    case CATEGORY_ACTIONS.SET_GET_CATEGORIES_PENDING:
      return SET_GET_CATEGORIES_PENDING(state);
    case CATEGORY_ACTIONS.SET_GET_CATEGORIES_SUCCEED:
      return SET_GET_CATEGORIES_SUCCEED(state, action);
    case CATEGORY_ACTIONS.SET_SYNC_MENU_ITEM_PENDING:
      return SET_SYNC_MENU_ITEM_PENDING(state, action);
    case CATEGORY_ACTIONS.SET_SYNC_MENU_ITEM_SUCCEED:
      return SET_SYNC_MENU_ITEM_SUCCEED(state);
    case CATEGORY_ACTIONS.SET_SYNC_MENU_ITEM_FAILURE:
      return SET_SYNC_MENU_ITEM_FAILURE(state);
    default:
      return state;
  }
};
