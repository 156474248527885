/* eslint-disable no-param-reassign */
const addScore = (score, fontFamily, elementFontFamily, fontSize, elementFontSize, data, isCapitalizeCheck) => {
  let familyScore = 0;
  let sizeScore = 0;
  let upperCaseScore = 0;

  if (fontFamily == elementFontFamily) familyScore = 1;
  sizeScore = (Math.min(fontSize, elementFontSize) / Math.max(fontSize, elementFontSize));

  if (isCapitalizeCheck && data.text.trim().toUpperCase() == data.text.trim()) upperCaseScore = 1;

  if (!isCapitalizeCheck) {
    data[score] = (familyScore + sizeScore) / 2;
  } else {
    data[score] = (familyScore + sizeScore + upperCaseScore) / 3;
  }
};

export default addScore;
