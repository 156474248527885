import type { BaseAction } from '../../common/types';

export const MEDIA_ACTIONS = {
  ATTEMPT_TO_UPLOAD_FILE: '@MEDIA//ATTEMPT_TO_UPLOAD_FILE',
  SET_MEDIA_UPLOAD_ERROR: '@MEDIA/SET_MEDIA_UPLOAD_ERROR',
  SET_MEDIA_UPLOAD_STATUS: '@MEDIA/SET_MEDIA_UPLOAD_STATUS',

  SET_UPLOADED_S3_URL: '@MEDIA/SET_UPLOADED_S3_URL',

  DELETE_KEY_IN_MEDIA_REDUCER: '@MEDIA/DELETE_KEY_IN_MEDIA_REDUCER',

  RESET_UPLOAD_INITIAL_STATE: '@MEDIA/RESET_UPLOAD_INITIAL_STATE'

};

/* ACTION CREATORS */

/**
 * action creator
 * @param {Binary} file - file
 * @param {String} extension - jwt
 * @param {String} storeKey - jwt
 * @returns {Object} - action
 */
export const attemptToUploadFile = (file, extension, storeKey): BaseAction => ({
  type: MEDIA_ACTIONS.ATTEMPT_TO_UPLOAD_FILE,
  payload: { file, extension, storeKey }
});

/**
 * action creator
 * @param {String} error -
 * @param {String} storeKey -
 * @returns {Object} - action
 */
export const setMediaUploadError = (error, storeKey): BaseAction => ({
  type: MEDIA_ACTIONS.SET_MEDIA_UPLOAD_ERROR,
  payload: { error, storeKey }
});

/**
 * action creator
 * @param {String} status -
 * @param {String} storeKey - specific key for the file
 * @returns {Object} - action
 */
export const setMediaUploadStatus = (status, storeKey): BaseAction => ({
  type: MEDIA_ACTIONS.SET_MEDIA_UPLOAD_STATUS,
  payload: { status, storeKey }
});

/**
 * action creator
 * @param {String} path - s3 url
 * @param {String} storeKey - specific key for the file
 * @returns {Object} - action
 */
export const setMediaUploadedUrl = (path, storeKey): BaseAction => ({
  type: MEDIA_ACTIONS.SET_UPLOADED_S3_URL,
  payload: { path, storeKey }
});

/**
 * action creator
 * @param {String} storeKey - specific key for the file
 * @returns {Object} - action
 */
export const deleteKeyInMediaReducer = (storeKey): BaseAction => ({
  type: MEDIA_ACTIONS.DELETE_KEY_IN_MEDIA_REDUCER,
  payload: storeKey
});

export const resetMediaUploadedUrl = (storeKey): BaseAction => ({
  type: MEDIA_ACTIONS.RESET_UPLOAD_INITIAL_STATE,
  payload: { storeKey }
});
