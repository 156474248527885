import { ACTION_STATUSES } from '../../utils/constants';
import { CUSTOMIZATION_ACTIONS } from '../actions/customization';
import { MenusState } from './menus';

const initialState: MenusState = {
  menuStyles: {
    sectionFont: {
      url: '',
      name: '',
      type: 'initial',
      weight: '',
      color: ''
    },
    dishFont: {
      url: '',
      name: '',
      type: 'initial',
      weight: '',
      color: ''
    },
    dishDescriptionFont: {
      url: '',
      name: '',
      type: 'initial',
      weight: '',
      color: ''
    },
    backgroundColor: '',
    outsideColor: '',
    footerColor: ''
  },
  error: null
};

const SUCCES_GET_STYLES = (state, action) => {
  return {
    ...state,
    menuStyles: action.payload,
    status: ACTION_STATUSES.SUCCEED
  };
};

const SET_CUSTOMIZATION_STATUS = (state: MenusState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMIZATION_ACTIONS.SUCCES_GET_STYLES:
      return SUCCES_GET_STYLES(state, action);
    case CUSTOMIZATION_ACTIONS.SET_CUSTOMIZATION_STATUS:
      return SET_CUSTOMIZATION_STATUS(state);
    default:
      return state;
  }
};
