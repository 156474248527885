// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { GMB_ACTIONS } from '../actions/gmb';

export type GMBState = {
  status: ?string,
  error: ?string,
  data: any,
};

const initialState = (): GMBState => ({
  status: null,
  error: null,
  data: {},
  locationData: {}
});

// eslint-disable-next-line no-unused-vars
const ATTEMPT_TO_GET_GMB_ACCOUNTS = (state: GMBState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  data: action.payload
});
const GET_GMB_ACCOUNTS_SUCCESS = (state: GMBState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  data: action.payload
});

const GET_GMB_LOCATIONS_SUCCESS = (state: GMBState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  locationData: action.payload
});
const SYNC_MENU_TO_GMB_SUCCESS = (state: GMBState) => ({
  ...state,
  status: null
});
export default (state: GMBState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case GMB_ACTIONS.ATTEMPT_TO_GET_GMB_ACCOUNTS:
      return ATTEMPT_TO_GET_GMB_ACCOUNTS(state, action);
    case GMB_ACTIONS.GET_GMB_ACCOUNTS_SUCCESS:
      return GET_GMB_ACCOUNTS_SUCCESS(state, action);
    case GMB_ACTIONS.GET_GMB_LOCATIONS_SUCCESS:
      return GET_GMB_LOCATIONS_SUCCESS(state, action);
    case GMB_ACTIONS.SYNC_MENU_TO_GMB_SUCCESS:
      return SYNC_MENU_TO_GMB_SUCCESS(state);
    default:
      return state;
  }
};
