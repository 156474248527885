import type { BaseAction } from '../../common/types';

export const CUSTOMIZATION_ACTIONS = {
  ATTEMPT_TO_CUSTOMIZE_MENU: '@CUSTOMIZATION_ACTIONS/ATTEMPT_TO_CUSTOMIZE_MENU',
  ATTEMPT_TO_CUSTOMIZE_AREA: '@CUSTOMIZATION_ACTIONS/ATTEMPT_TO_CUSTOMIZE_AREA',
  ATTEMPT_TO_EDIT_STYLES: '@CUSTOMIZATION_ACTIONS/ATTEMPT_TO_EDIT_STYLES',
  ATTEMPT_TO_GET_STYLES: '@CUSTOMIZATION_ACTIONS/ATTEMPT_TO_GET_STYLES',
  SET_CUSTOMIZATION_STATUS: '@CUSTOMIZATION_ACTIONS/SET_CUSTOMIZATION_STATUS',
  SUCCES_GET_STYLES: '@CUSTOMIZATION_ACTIONS/SUCCES_GET_STYLES'
};

export const attemptToEditStyles = (payload: Object): BaseAction => ({
  type: CUSTOMIZATION_ACTIONS.ATTEMPT_TO_EDIT_STYLES,
  payload
});

export const setMenuSectionsStatus = (payload: Object): BaseAction => ({
  type: CUSTOMIZATION_ACTIONS.SET_CUSTOMIZATION_STATUS,
  payload
});

export const succesGetStyles = (payload: Object): BaseAction => ({
  type: CUSTOMIZATION_ACTIONS.SUCCES_GET_STYLES,
  payload
});

export const AttemptToGetStyles = (payload: Object): BaseAction => ({
  type: CUSTOMIZATION_ACTIONS.ATTEMPT_TO_GET_STYLES,
  payload
});
