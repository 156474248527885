/* eslint-disable no-underscore-dangle */
// @flow

import MenuParser from '../../modules/menuParser';
import type { IndexSignature } from '../../common/types';
import type { MenuDetails, Menu } from '../../types/menu';

type Props = {
  details: MenuDetails,
  menu: IndexSignature<Menu>
};

export default class ExportToXLSX {
  static toast({ details, menu }: Props) {
    const sanitizeData = (data: IndexSignature<Menu>) => {
      const result = {
        menuBuild: [],
        modifierBuild: []
      };

      const menuItems: Array<Menu> = Object.keys(data).map(key => data[key]);

      menuItems.forEach((item: Menu) => {
        result.menuBuild.push({
          text: item.sectionName,
          items: item.dishList.map(dish => ({
            text: dish.dishName,
            price: dish.dishPrice,
            description: dish.dishDescription || '',
            options: dish.dishOptions || []
          }))
        });
        item.dishList.forEach(dish => {
          if (dish.dishOptions) {
            dish.dishOptions.forEach(option => {
              result.modifierBuild.push(option);
            });
          }
        });
      });
      return result;
    };

    MenuParser.exportToToastXLSX(sanitizeData(menu), {
      MenuInternalName:
      `${details.restaurantName}${details.restaurantName && details.menuName && '-'}${details.menuName}`
    });
  }
}
