import { request } from './request';
import { apiConfig } from '../../config';

const { CATEGORIES_API_URL, CATEGORIES_API_TOKEN } = apiConfig;

const HEADERS = {
  lang: 'en',
  token: CATEGORIES_API_TOKEN
};

export const post = async (path, data) => {
  return request('POST', `${CATEGORIES_API_URL}${path}`, false, false, { headers: HEADERS, data });
};
