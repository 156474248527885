// flow
import type { BaseAction } from '../../common/types';

export const AUTH_ACTIONS = {
  ATTEMPT_TO_SIGN_IN: '@AUTH/ATTEMPT_TO_SIGN_IN',
  SET_SIGN_IN_SUCCEED: '@AUTH/SET_SIGN_IN_SUCCEED',
  SET_SIGN_IN_FAILURE: '@AUTH/SET_SIGN_IN_FAILURE',
  ATTEMPT_TO_LOG_OUT: '@AUTH/ATTEMPT_TO_LOG_OUT'
};

export const attemptToSignIn = (payload: Object): BaseAction => ({
  type: AUTH_ACTIONS.ATTEMPT_TO_SIGN_IN,
  payload
});

export const setSignInSucceed = (payload: Object): BaseAction => ({
  type: AUTH_ACTIONS.SET_SIGN_IN_SUCCEED,
  payload
});

export const setSignInFailure = (payload: Object): BaseAction => ({
  type: AUTH_ACTIONS.SET_SIGN_IN_FAILURE,
  payload
});

export const attemptToLogout = (): BaseAction => ({
  type: AUTH_ACTIONS.ATTEMPT_TO_LOG_OUT
});
