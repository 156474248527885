// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { USER_ACTIONS } from '../actions/user';

export type UserState = {
  status: ?string,
  error: ?string,
  data: ?any
};

const initialState = (): UserState => ({
  status: null,
  error: null,
  data: []
});

const ATTEMPT_TO_GET_USER = (state: UserState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

// eslint-disable-next-line no-unused-vars
const SET_USER_SUCCEED = (state: UserState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  data: action.payload
});

const SET_USER_FAILURE = (state: UserState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});

export default (state: UserState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case USER_ACTIONS.ATTEMPT_TO_GET_USER:
      return ATTEMPT_TO_GET_USER(state);
    case USER_ACTIONS.SET_USER_SUCCEED:
      return SET_USER_SUCCEED(state, action);
    case USER_ACTIONS.SET_USER_FAILURE:
      return SET_USER_FAILURE(state, action);
    default:
      return state;
  }
};
