// @flow

import { MEDIA_ACTIONS } from '../actions/media';

import type { BaseAction, MediaUploadState } from '../../common/types';

type MediaState = {
    upload: MediaUploadState
};

const initialUploadState = (): MediaUploadState => ({
  status: null,
  error: null,
  s3_url: null
});

const initialState = (): MediaState => ({
  upload: initialUploadState()
});

// Reducers

/**
 * SET_MEDIA_UPLOAD_ERROR Reducer
 * @param {MediaState} state - state object
 * @param {BaseAction} action - action object
 * @returns {Object} - new state
 */
const SET_MEDIA_UPLOAD_ERROR = (state: MediaState, action: BaseAction) => {
  const uploadKey = action?.payload?.storeKey || 'upload';
  return {
    ...state,
    [uploadKey]: {
      ...state[uploadKey],
      error: action?.payload?.error
    }
  };
};

/**
 * SET_MEDIA_UPLOAD_STATUS Reducer
 * @param {MediaState} state - state object
 * @param {BaseAction} action - action object
 * @returns {Object} - new state
 */
const SET_MEDIA_UPLOAD_STATUS = (state: MediaState, action: BaseAction) => {
  const uploadKey = action?.payload?.storeKey || 'upload';
  return {
    ...state,
    [uploadKey]: {
      ...state[uploadKey],
      status: action?.payload?.status
    }
  };
};

/**
 * SET_UPLOADED_S3_URL Reducer
 * @param {MediaState} state - state object
 * @param {BaseAction} action - action object
 * @returns {Object} - new state
 */
const SET_UPLOADED_S3_URL = (state: MediaState, action: BaseAction) => {
  const uploadKey = action?.payload?.storeKey || 'upload';
  return {
    ...state,
    [uploadKey]: {
      ...state[uploadKey],
      s3_url: action?.payload?.path
    }
  };
};

/**
 * SET_UPLOADED_S3_URL Reducer
 * @param {MediaState} state - state object
 * @param {BaseAction} action - action object
 * @returns {Object} - new state
 */
const DELETE_KEY_IN_MEDIA_REDUCER = (state: MediaState, action: BaseAction) => {
  const uploadKey = action?.payload;
  const newState = { ...state };
  if (uploadKey) delete newState[uploadKey];

  return {
    ...newState
  };
};

/**
 * RESET_UPLOAD_INITIAL_STATE Reducer
 * @param {MediaState} state - state
 * @param {Object} action - state
 * @returns {Object} - new state
 */
const RESET_UPLOAD_INITIAL_STATE = (state, action) => {
  const uploadKey = action?.payload?.storeKey || 'upload';
  return {
    ...state,
    [uploadKey]: initialUploadState()
  };
};

export default (state: MediaState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case MEDIA_ACTIONS.SET_MEDIA_UPLOAD_STATUS:
      return SET_MEDIA_UPLOAD_STATUS(state, action);
    case MEDIA_ACTIONS.SET_UPLOADED_S3_URL:
      return SET_UPLOADED_S3_URL(state, action);
    case MEDIA_ACTIONS.SET_MEDIA_UPLOAD_ERROR:
      return SET_MEDIA_UPLOAD_ERROR(state, action);

    case MEDIA_ACTIONS.DELETE_KEY_IN_MEDIA_REDUCER:
      return DELETE_KEY_IN_MEDIA_REDUCER(state, action);

    case MEDIA_ACTIONS.RESET_UPLOAD_INITIAL_STATE:
      return RESET_UPLOAD_INITIAL_STATE(state, action);

    default:
      return state;
  }
};
