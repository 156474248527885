// flow
import { takeLatest, put, debounce } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { get, post } from '../../modules/api/request';
import { CUSTOMIZATION_ACTIONS, succesGetStyles } from '../actions/customization';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

function* getRestStyles({ payload }) {
  const { restaurant_id } = payload;
  try {
    const data = yield get(`v1/restaurant/${restaurant_id}/customization`);
    if (data.error) return;
    yield put(succesGetStyles(data));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editStyles({ payload }) {
  const { restaurant_id, styles } = payload;
  try {
    yield post(`v1/restaurant/${restaurant_id}/customization/`, styles);
    yield put(succesGetStyles(styles));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* MenuCustomization<T>(): Iterable<T> {
  yield takeLatest(CUSTOMIZATION_ACTIONS.ATTEMPT_TO_GET_STYLES, getRestStyles);
  yield debounce(300, CUSTOMIZATION_ACTIONS.ATTEMPT_TO_EDIT_STYLES, editStyles);
}

export default MenuCustomization;
