const exportToYEXTCSV = (
  data = [],
  {
    MenuInternalName = 'N/A',
    MenuPublishedName = 'N/A',
    maxOptionsLength = 1
  }
) => {
  const csvTitles = [
    'MenuInternalName',
    'MenuPublishedName',
    'MenuLanguage',
    'MenuCurrency',
    'SectionName',
    'SectionDescription',
    'ItemName',
    'ItemPrice'
  ];
  for (let i = 0; i < maxOptionsLength; i++) {
    csvTitles.push('ItemOption');
  }
  csvTitles.push('ItemDescription');
  const csvData = [csvTitles];

  for (let i = 0; i < data.length; i++) {
    const { items, text, description } = data[i];

    for (let j = 0; j < items.length; j++) {
      const { child, text: dishName, price } = items[j];

      if (dishName) {
        const rowItems = [
          MenuInternalName,
          i === 0 && j === 0 ? MenuPublishedName : '',
          'en',
          'USD',
          `"${text}"`,
          j === 0 && description ? `"${description}"` : '',
          `"${dishName}"`,
          price
        ];

        for (let k = 0; k < maxOptionsLength; k++) {
          const option = child.options[k];
          let optionText: string = '';
          if (option) {
            const { name: optionName, price: optionPrice } = option;
            optionText = `${optionName}${optionPrice ? `|$${optionPrice}` : ''}`;
          }
          rowItems.push(optionText);
        }

        rowItems.push(`"${child.text}"`);

        csvData.push(rowItems);
      }
    }
  }

  const csvContent = csvData.map(e => e.join(',')).join('\n');

  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv; charset=utf-8,${encodeURIComponent(csvContent)}`);
  link.setAttribute('download', `${MenuInternalName}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
};

export default exportToYEXTCSV;
