// flow
import type { BaseAction } from '../../common/types';

export const ON_BOARDING_ACTIONS = {
  SAVE_ON_BOARDING_DETAILS: '@ON_BOARDING/SAVE_ON_BOARDING_DETAILS',
  ATTEMPT_TO_UPLOAD_MENU_FILE: '@ON_BOARDING/ATTEMPT_TO_UPLOAD_MENU_FILE',
  SET_UPLOAD_MENU_FILE_SUCCEED: '@ON_BOARDING/SET_UPLOAD_MENU_FILE_SUCCEED',
  SET_UPLOAD_MENU_FILE_FAILURE: '@ON_BOARDING/SET_UPLOAD_MENU_FILE_FAILURE',
  RESET_ON_BOARDING_STATE: '@ON_BOARDING/RESET_ON_BOARDING_STATE'
};

export const saveOnBoardingDetails = (payload: Object): BaseAction => ({
  type: ON_BOARDING_ACTIONS.SAVE_ON_BOARDING_DETAILS,
  payload
});

export const attemptToUploadMenuFile = (payload: Object): BaseAction => ({
  type: ON_BOARDING_ACTIONS.ATTEMPT_TO_UPLOAD_MENU_FILE,
  payload
});

export const setUploadMenuFileSucceed = (payload: Object): BaseAction => ({
  type: ON_BOARDING_ACTIONS.SET_UPLOAD_MENU_FILE_SUCCEED,
  payload
});

export const setUploadMenuFileFailure = (payload: Object): BaseAction => ({
  type: ON_BOARDING_ACTIONS.SET_UPLOAD_MENU_FILE_FAILURE,
  payload
});

export const resetOnBoardingState = (): BaseAction => ({
  type: ON_BOARDING_ACTIONS.RESET_ON_BOARDING_STATE
});
