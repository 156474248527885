import addScore from '../../helpers/score';
import addPriceScore from '../../helpers/score/add-price-score';
import mergeNearestElementsByCoordinates from '../../helpers/merge-nearest-elements-by-coordinates';
import createMatrix from '../../helpers/create-matrix';
import dijkstra from '../../helpers/dijkstra';
import setCoordinates from '../../helpers/set-coordinates';
import setDishWithEmptyNameScoreToZero from '../../helpers/set-dish-with-empty-name-score-to-zero';
import setMultipleSectionNameScoreToZero from '../../helpers/set-multiple-section-name-score-to-zero';
// eslint-disable-next-line max-len
import removeDotAreaAndMergeCoordinatesToPriceArea from '../../helpers/remove-dot-area-and-merge-coordinates-to-price-area';
import capitalizeCheck from '../../helpers/capitalize-check';
import exportToYEXTCSV from '../../helpers/export-methods/export-to-yext-csv';
import exportToBBOTCSV from '../../helpers/export-methods/export-to-bbot-csv';
import exportToToastXLSX from '../../helpers/export-methods/export-to-toast-xlsx-csv';

export default class MenuParser {
  static data = null;

  static callback: Function = null;

  static params = null;

  static eventBound = false;

  static MIN_SCORE = null;

  static init(data) {
    if (!MenuParser.eventBound) {
      MenuParser.getDataHandler(data);
    }
  }

  static getDataHandler({ detail: { data } }) {
    // TODO test
    // process data
    MenuParser.parser.setData(data);
    MenuParser.parser.setInput(MenuParser.params);
    const processedData = MenuParser.parser.processData();
    MenuParser.callback(processedData);
  }

  static selectedAreaDataModify(selectedAreaData, extractedMenuData) {
    const defaultStyles = { style: { fontFamily: '', fontSize: '' } };
    const { sectionName, name: dishName, description: dishDescription, price: dishPrice } = extractedMenuData;

    const { style: { fontFamily: sectionFontFamily, fontSize: sectionFontSize } } = sectionName || defaultStyles;
    const { style: { fontFamily: dishNameFontFamily, fontSize: dishNameFontSize } } = dishName || defaultStyles;
    const { style: { fontFamily: dishDescriptionFontFamily, fontSize: dishDescriptionFontSize } } = dishDescription || defaultStyles;
    const { style: { fontFamily: dishPriceFontFamily, fontSize: dishPriceFontSize } } = dishPrice || defaultStyles;
    for (let i = 0; i < selectedAreaData.length; i++) {
      for (let j = 0; j < selectedAreaData[i].data.length; j++) {
        const data = selectedAreaData[i].data[j];

        data.sectionNameScore = 0;
        data.dishNameScore = 0;
        data.dishDescriptionScore = 0;
        data.dishPriceScore = 0;

        const { style: { fontFamily: elementFontFamily, fontSize: elementFontSize } } = data;

        addScore('sectionNameScore', sectionFontFamily, elementFontFamily, sectionFontSize, elementFontSize, data);
        addScore('dishNameScore', dishNameFontFamily, elementFontFamily, dishNameFontSize, elementFontSize, data);
        addScore(
          'dishDescriptionScore',
          dishDescriptionFontFamily,
          elementFontFamily,
          dishDescriptionFontSize,
          elementFontSize,
          data
        );
        addPriceScore(
          'dishPriceScore',
          dishPriceFontFamily,
          elementFontFamily,
          dishPriceFontSize,
          elementFontSize,
          data
        );
      }
    }
  }

  static getDivGroups(dijkstraResult, selectedAreaData) {
    return dijkstraResult.map((points, i) => MenuParser.createDivsGroup(points, selectedAreaData[i].data));
  }

  /* Group divs by distance matrix */
  static createDivsGroup(result, selectedData) {
    const groups = new Set();

    result.forEach(data => {
      const pointsToString = data.map((i, idx) => ({ i, idx }))
        .filter(({ i }) => i != Infinity)
        .map(({ idx }) => idx).sort((a, b) => a - b)
        .join(',');

      groups.add(pointsToString);
    });

    return Array.from(groups).map(i => i.split(',').map(idx => selectedData[idx]));
  }

  static getDistances(selectedAreaData) {
    return selectedAreaData.map(selectedArea => ({ id: selectedArea.id, data: createMatrix(selectedArea.data) }));
  }

  static getDijkstraResult(selectedAreaData, distances) {
    const result = [];

    for (let i = 0; i < selectedAreaData.length; i++) {
      const { data } = selectedAreaData[i];
      result[i] = [];

      for (let j = 0; j < data.length; j++) {
        result[i].push(dijkstra(distances[i].data, j));
      }
    }

    return result;
  }

  static getDishGroups(menuData, selectedAreaData) {
    return menuData.map(data => {
      setDishWithEmptyNameScoreToZero(data?.dishList, selectedAreaData);

      return { ...data, dishList: data.dishList.filter(i => typeof i.dishName !== 'undefined') };
    }).map((item) => {
      return { ...item, dishList: item.dishList.sort((a, b) => 1.1 * (a.coordinates?.top - b.coordinates?.top) + (a.coordinates?.left - b.coordinates?.left)) };
    });
  }

  static createMenuData(selectedAreaData, extractedMenuData) {
    const menuData = [];
    const dishItemScoreNames = ['sectionNameScore', 'dishNameScore', 'dishDescriptionScore', 'dishPriceScore'];
    const sectionNames = selectedAreaData.map(group => group.data.filter(item => item.sectionNameScore > MenuParser.MIN_SCORE));
    if (sectionNames?.length) setMultipleSectionNameScoreToZero(sectionNames);

    const selectedAreaDataWithoutSectionNames = selectedAreaData.map(({ id, data }) => ({ id, data: data.filter(item => item.sectionNameScore <= MenuParser.MIN_SCORE) }));

    const distances = MenuParser.getDistances(selectedAreaDataWithoutSectionNames);
    const dijkstraResult = MenuParser.getDijkstraResult(selectedAreaDataWithoutSectionNames, distances);
    const divsGroups = MenuParser.getDivGroups(dijkstraResult, selectedAreaDataWithoutSectionNames);
    divsGroups.map((divsGroup, index) => {
      if (!menuData[index]) menuData[index] = {};
      if (!menuData[index].dishList) menuData[index].dishList = [];

      return divsGroup.map((group, i) => {
        capitalizeCheck(group, extractedMenuData);
        mergeNearestElementsByCoordinates(group);
        removeDotAreaAndMergeCoordinatesToPriceArea(group);

        for (let i = 0; i < group.length; i++) {
          if (!/[\w|$]/.test(group[i].text)) {
            group.splice(i, 1);
            i--;
          }
        }

        return dishItemScoreNames.map(score => {
          const filteredGroups = group.filter(item => {
            if (item.dishPriceScore > MenuParser.MIN_SCORE) {
              return score === 'dishPriceScore';
            }
            if (score === 'sectionNameScore' || item[score] <= MenuParser.MIN_SCORE) {
              return item[score] > MenuParser.MIN_SCORE;
            }
            switch (score) {
              case 'dishDescriptionScore':
                return item[score] > item.dishNameScore;
              case 'dishNameScore':
                return item[score] > item.dishDescriptionScore;
              default:
            }
          });

          if (filteredGroups.length) {
            return filteredGroups.reduce(
              (acc, current) => {
                const { coordinates: { left: group_sum_left, right: group_sum_right, top: group_sum_top, bottom: group_sum_bottom } } = acc;
                const { coordinates: { left, right, top, bottom } } = current;

                return {
                  id: `${acc.id} ${current.id}`,
                  [score]: `${acc[score]?.trim()} ${current.text?.trim()}`,
                  coordinates: setCoordinates(current.coordinates, acc.coordinates)
                };
              }, { id: '', [score]: '', coordinates: { left: Infinity, right: Infinity, top: Infinity, bottom: Infinity } }
            );
          }
          return null;
        });
      });
    })
      .forEach((group, i) => {
        if (group.length) {
          if (!menuData[i]) menuData[i] = {};
          if (!menuData[i].dishList) menuData[i].dishList = [];
          if (sectionNames[i]) menuData[i].sectionName = sectionNames[i][0]?.text?.trim() || '';

          group.filter(el => el != null)
            .forEach((item, index) => {
              item.filter(el => el != null)
                .forEach((item1) => {
                  const { dishList } = menuData[i];
                  const { dishNameScore } = item1;
                  const { dishDescriptionScore } = item1;
                  const { dishPriceScore } = item1;

                  if (!dishList[index]) dishList[index] = {};

                  if (dishNameScore) dishList[index].dishName = dishNameScore.trim();
                  if (item1.id) dishList[index].id = dishList[index].id ? `${dishList[index].id} ${item1.id}` : item1.id;

                  if (item1.coordinates) {
                    if (!dishList[index].coordinates) {
                      dishList[index].coordinates = { ...item1.coordinates };
                    } else {
                      dishList[index].coordinates = setCoordinates(dishList[index].coordinates, item1.coordinates);
                    }
                  }

                  if (dishDescriptionScore) dishList[index].dishDescription = dishDescriptionScore.trim();
                  if (dishPriceScore) dishList[index].dishPrice = dishPriceScore.trim().replace('| ', '').replace('$', '');
                });
            });
        }
      });

    return menuData;
  }

  static exportToYEXTCSV(data, params) {
    exportToYEXTCSV(data, params);
  }

  static exportToBBOTCSV(data, params) {
    exportToBBOTCSV(data, params);
  }

  static exportToToastXLSX(data, params) {
    exportToToastXLSX(data, params);
  }
}
