// flow
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const OnBoarding = lazy(() => import('./OnBoarding'));
const MenuUploaderCreate = lazy(() => import('./MenuUploader/Create'));
const MenuBuilder = lazy(() => import('./MenuUploader/common/menu-builder'));
const Publish = lazy(() => import('./MenuUploader/Publish'));
const Profile = lazy(() => import('./profile'));
const Settings = lazy(() => import('./settings'));
const SignIn = lazy(() => import('./SignIn'));
const SignUp = lazy(() => import('./SignUp'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const ChangePassword = lazy(() => import('./ChangePassword'));

export const PRIVATE_ROUTES = [
  {
    path: '/menu-uploader/create/:id',
    component: MenuUploaderCreate,
    exact: false,
    key: 'create'
  },
  {
    path: '/menu-uploader/publish/:id',
    component: Publish,
    exact: false,
    key: 'publish'
  },
  {
    path: '/menu-uploader/:id',
    component: OnBoarding,
    exact: false,
    key: 'onBoarding'
  },
  {
    path: '/create-menu/:id',
    component: OnBoarding,
    exact: false,
    key: 'createMenu'
  },
  {
    path: '/create-ordering-menu/:id',
    component: OnBoarding,
    exact: false,
    key: 'createOrderingMenu'
  },
  {
    path: '/menu-builder/:id',
    component: MenuBuilder,
    exact: false,
    key: 'menuBuilder'
  },
  {
    path: '/menu-builder-ordering/:id',
    component: MenuBuilder,
    exact: false,
    key: 'menuBuilderOrdering'
  },
  {
    path: '/',
    component: Profile,
    exact: true,
    key: 'profile'
  },
  {
    path: '/profile-empty',
    component: Profile,
    exact: true,
    key: 'profile-empty'
  },
  {
    path: '/edit-restaurant/:id',
    component: Profile,
    exact: false,
    key: 'edit-restaurant'
  },
  {
    path: '/create-restaurant',
    component: Profile,
    exact: true,
    key: 'create-restaurant'
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
    key: 'settings'
  },
  {
    path: '*',
    component: () => <Redirect to="/" />,
    exact: false,
    key: 'matchAll'
  }
];

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: SignIn,
    exact: true,
    key: 'signIn'
  },
  {
    path: '/sign-in',
    component: SignIn,
    exact: true,
    key: 'signIn'
  },
  {
    path: '/sign-up',
    component: SignUp,
    exact: true,
    key: 'signUp'
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
    key: 'forgot-password'
  },
  {
    path: '/member/:id/reset-password/:token',
    component: ChangePassword,
    exact: false,
    key: 'change-password'
  },
  {
    path: '*',
    component: () => <Redirect to="/" />,
    exact: false,
    key: 'matchAll'
  }
];
