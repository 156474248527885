import getPointsFromElement from './get-points-from-element';
import getMinDistance from './distance/get-min-distance';
import { CONSTANTS } from '../config';

const createMatrix = (selectedData) => {
  const distances = [];

  for (let i = 0; i < selectedData.length; i++) {
    const { coordinates: { left: x1, top: y1, height: h1, width: w1 } } = selectedData[i];

    const arr = getPointsFromElement(y1, x1, h1, w1);

    for (let j = 0; j < selectedData.length; j++) {
      const { coordinates: { left: x, top: y, height: h, width: w } } = selectedData[j];

      let distance = 0;
      if (i !== j) {
        const arr1 = getPointsFromElement(y, x, h, w);

        distance = getMinDistance(arr, arr1);
      }

      if (!distances[i]) distances[i] = [];

      if (distance < CONSTANTS.MIN_DISTANCE) {
        distances[i][j] = distance;
      } else {
        distances[i][j] = Infinity;
      }
    }
  }

  return distances;
};

export default createMatrix;
