// flow
import { takeLatest, put } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { ON_BOARDING_ACTIONS, setUploadMenuFileFailure, setUploadMenuFileSucceed } from '../actions/onBoarding';

import { apiConfig } from '../../config';
import { post } from '../../modules/api/request';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

const { API_TOKEN, HANDLE_URL } = apiConfig;

function* uploadMenuFile({ payload }) {
  try {
    const formData = new FormData();
    formData.append('menu', payload);
    formData.append('api_token', API_TOKEN);
    formData.append('handler_url', HANDLE_URL);
    const data = yield post('v1/menu_uploader/upload_pdf', formData, false);

    yield put(setUploadMenuFileSucceed(data));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setUploadMenuFileFailure(error));
  }
}

function* onBoardingSaga<T>(): Iterable<T> {
  yield takeLatest(ON_BOARDING_ACTIONS.ATTEMPT_TO_UPLOAD_MENU_FILE, uploadMenuFile);
}

export default onBoardingSaga;
