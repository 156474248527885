// flow
import { takeLatest, put } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { post } from '../../modules/api/request';
import { setSignUpFailure, REGISTER_ACTIONS } from '../actions/signUp';
import { AUTH_STORAGE_NAME } from '../../utils/constants';
import StorageManager from '../../utils/StorageManager';
import redirectToHomePage from '../../utils/redirectToHomePage';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

function* signUp({ payload }) {
  try {
    const { email, password, name } = payload;
    const data = yield post('v1/signup/', { email, password, full_name: name }, false);
    StorageManager.setItem(AUTH_STORAGE_NAME, data);
    redirectToHomePage();
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error('Something went wrong, please try again later');
    yield put(setSignUpFailure(error));
  }
}

function* signUpSaga<T>(): Iterable<T> {
  yield takeLatest(REGISTER_ACTIONS.ATTEMPT_TO_SIGN_UP, signUp);
}

export default signUpSaga;
