// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { AUTH_ACTIONS } from '../actions/auth';

export type SignInState = {
  status: ?string,
  error: ?string,
};

const initialState = (): SignInState => ({
  status: null,
  error: null
});

const ATTEMPT_TO_SIGN_IN = (state: SignInState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

// eslint-disable-next-line no-unused-vars
const SET_SIGN_IN_SUCCEED = (state: SignInState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED
});

const SET_SIGN_IN_FAILURE = (state: SignInState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});

export default (state: SignInState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case AUTH_ACTIONS.ATTEMPT_TO_SIGN_IN:
      return ATTEMPT_TO_SIGN_IN(state);
    case AUTH_ACTIONS.SET_SIGN_IN_SUCCEED:
      return SET_SIGN_IN_SUCCEED(state, action);
    case AUTH_ACTIONS.SET_SIGN_IN_FAILURE:
      return SET_SIGN_IN_FAILURE(state, action);
    default:
      return state;
  }
};
