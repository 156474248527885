// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { GOOGLE_ACTIONS } from '../actions/googleFonts';

export type GoogleState = {
  list: Object[]
};

const initialState = (): GoogleState => ({
  list: []
});

const ATTEMPT_TO_GET_GOOGLE_FONTS = (state: GoogleState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

const SET_GET_GOOGLE_FONTS_SUCCEED = (state: GoogleState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  list: action.payload && action.payload.googleFonts
});

export default (state: GoogleState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case GOOGLE_ACTIONS.ATTEMPT_TO_GET_GOOGLE_FONTS:
      return ATTEMPT_TO_GET_GOOGLE_FONTS(state);
    case GOOGLE_ACTIONS.SET_GET_GOOGLE_FONTS_SUCCEED:
      return SET_GET_GOOGLE_FONTS_SUCCEED(state, action);
    default:
      return state;
  }
};
