import { debounce, put, takeLatest } from 'redux-saga/effects';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

import TOAST from '../../modules/toastManager';
import { get, post, sendDelete } from '../../modules/api/request';
import { BANNER_ACTIONS, getBannerData } from '../actions/banner';

function* getBanner({ payload }) {
  const { menuId, orderingType } = payload;
  try {
    const data = yield get(`v1/${orderingType}_menu_banner/${menuId}`);
    yield put((getBannerData(data)));
  } catch (err) {
    sagaErrorHandler(err);
  }
}
function* sendBannerData({ payload }) {
  const { menuId, orderingType, banner, link } = payload;
  const formData = new FormData();
  formData.append('image', banner);
  formData.append('link', link);

  try {
    const data = yield post(`v1/${orderingType}_menu/${menuId}/banner/`, formData);
    yield put((getBannerData(data)));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}
function* deleteBannerData({ payload }) {
  const { menuId, orderingType } = payload;
  try {
    yield sendDelete(`v1/${orderingType}_menu_banner/${menuId}`);
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}
function* bannerSaga<T>(): Iterable<T> {
  yield takeLatest(BANNER_ACTIONS.ATTEMPT_TO_GET_BANNER_DATA, getBanner);
  yield takeLatest(BANNER_ACTIONS.DELETE_BANNER_DATA, deleteBannerData);
  yield debounce(500, BANNER_ACTIONS.SET_BANNER_DATA, sendBannerData);
}

export default bannerSaga;
