// @flow
import MenuParser from '../../modules/menuParser';
import type { IndexSignature } from '../../common/types';
import type { MenuDetails, Menu } from '../../types/menu';

type Props = {
  details: MenuDetails,
  menu: IndexSignature<Menu>
};
export default class ExportToCSV {
  static yext({ details, menu }: Props) {
    const sanitizeData = (data: IndexSignature<Menu>) => {
      const menuItems: Array<Menu> = Object.keys(data).map(key => data[key]);

      return menuItems.map(item => ({
        text: item.sectionName,
        description: item.sectionDescription,
        items: item.dishList.map(dish => ({
          text: dish.dishName,
          price: dish.dishPrice,
          child: {
            text: dish.dishDescription || '',
            options: dish.dishOptions || []
          }
        }))
      }));
    };
    const getMaxOptionsLength = (data) => {
      let maxLength = 1;
      const menuItems: Array<Menu> = Object.keys(data).map(key => data[key]);

      menuItems.forEach(({ dishList }) => {
        for (let i = 0; i < dishList.length; i++) {
          const { dishOptions } = dishList[i];
          if (dishOptions && Array.isArray(dishOptions) && maxLength < dishOptions.length) {
            maxLength = dishOptions.length;
          }
        }
      });
      return maxLength;
    };

    MenuParser.exportToYEXTCSV(sanitizeData(menu), {
      // eslint-disable-next-line max-len
      MenuInternalName: `${details.restaurantName}${details.restaurantName && details.menuName && '-'}${details.menuName}`,
      MenuPublishedName: details.menuName,
      maxOptionsLength: getMaxOptionsLength(menu)
    });
  }

  static bbot({ details, menu }: Props) {
    const sanitizeData = (data: Object) => {
      const menuItems: Array<Menu> = Object.keys(data).map(key => data[key]);

      return menuItems.map(item => ({
        text: item.sectionName,
        items: item.dishList.map(dish => ({
          text: dish.dishName,
          price: dish.dishPrice,
          description: dish.dishDescription || '',
          options: dish.dishOptions || []
        }))
      }));
    };

    MenuParser.exportToBBOTCSV(sanitizeData(menu), {
      // eslint-disable-next-line max-len
      MenuInternalName: `${details.restaurantName}${details.restaurantName && details.menuName && '-'}${details.menuName}`
    });
  }
}
