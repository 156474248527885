import addScore from './score';
import { CONSTANTS } from '../config';

const capitalizeCheck = (group, extractedMenuData) => {
  const result = [];

  group.forEach((item, i) => {
    group.forEach((item1, j) => {
      if (
        item.style.fontFamily == item1.style.fontFamily &&
        Math.abs(item.style.fontSize - item1.style.fontSize) < 2 &&
        !CONSTANTS.PRICE_REGEX.test(item.text) &&
        !CONSTANTS.PRICE_REGEX.test(item1.text) &&
        i != j
      ) {
        result.push(item);
      }
    });
  });

  if (result.length) {
    const defaultStyles = { style: { fontFamily: '', fontSize: '', text: '' } };
    const { name: dishName } = extractedMenuData;

    const { style: { fontFamily: dishNameFontFamily, fontSize: dishNameFontSize } } = dishName || defaultStyles;

    result.forEach(item => {
      const { style: { fontFamily: elementFontFamily, fontSize: elementFontSize } } = item;

      if (item.text.trim().toUpperCase() == item.text.trim()) {
        // eslint-disable-next-line no-param-reassign
        item.dishDescriptionScore = 0;
        // eslint-disable-next-line no-param-reassign
        item.dishPriceScore = 0;
      }

      addScore('dishNameScore', dishNameFontFamily, elementFontFamily, dishNameFontSize, elementFontSize, item, true);
    });
  }
};

export default capitalizeCheck;
