// flow
import { takeLatest, put, debounce } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { get, post, sendDelete, put as sendPut } from '../../modules/api/request';
import {
  setGetMenusSuccess,
  setGetMenusfailure,
  MENU_ACTIONS,
  setGetMenufailure,
  setGetMenuSuccess,
  setCreateMenuSuccess,
  setCreateMenufailure,
  attemptToGetOnlineMenus,
  deleteMenuSuccess,
  hidePublishSuccess,
  attemptToGetAllOrderingMenus
} from '../actions/menus';
import { attemptToGetSections, attemptToGetOrderSections } from '../actions/sections';
import { setSelectedRestaurant } from '../actions/restaurants';
import { attemptToCreateSection, attemptToRemoveDish } from '../actions/menuBuilder';
import { attemptToPublishMenu } from '../actions/menuUploader';
import { AttemptToGetStyles } from '../actions/customization';
import sagaErrorHandler from '../../utils/sagaErrorHandler';
import { menuDownloadSections, transformMainInfo, transformSections } from '../../utils/TransformMenuForDownoad';

function* getOnlineMenus({ payload }) {
  const { id, name } = payload;
  try {
    yield put(setSelectedRestaurant({ restId: id, restName: name }));
    if (id) {
      const onlineMenu = yield get(`v1/restaurant/${id}/web_menus`);
      yield put(setGetMenusSuccess({ onlineMenu, name }));
    }
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenusfailure(error));
  }
}
function* getAllOrderingMenus({ payload }) {
  const { id, name } = payload;
  try {
    yield put(setSelectedRestaurant({ restId: id, restName: name }));
    if (id) {
      const orderingMenu = yield get(`v1/restaurant/${id}/ordering_menus`);
      yield put(setGetMenusSuccess({ orderingMenu, name }));
      orderingMenu.menus = orderingMenu.menus?.filter((e) => e);
    }
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenusfailure(error));
  }
}
function* getListOrderingMenus({ payload }) {
  const { id, menuType } = payload;
  try {
    const menuList = yield get('v1/list_ordering_menus', { restaurant_id: id, menu_type: menuType });
    yield put(setGetMenusSuccess({ menuList }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenusfailure(error));
  }
}
function* downloadMenu({ payload }) {
  const { menuId, type, restName } = payload;
  try {
    const menuInfo = yield get(`v1/web_menu/${menuId}`);
    const menuDetails = transformMainInfo(menuInfo, restName);
    const sections = transformSections(menuInfo);
    yield put(attemptToPublishMenu({ menu: menuDownloadSections(sections), details: menuDetails, type }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* getMenu({ payload }) {
  const { id } = payload;
  try {
    const data = yield get(`v1/web_menu/${id}`);
    yield put(setGetMenuSuccess({ data, menuId: id }));
    yield put(AttemptToGetStyles(data));
    if (!data.sections.length) {
      yield put(attemptToCreateSection({ id, sectionName: '', sectionDescription: '' }));
      return;
    }
    yield put(attemptToGetSections({ id }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenufailure(error));
  }
}

function* getOrderingMenu({ payload }) {
  const { id } = payload;
  try {
    const data = yield get(`v1/ordering_menu/${id}`);
    yield put(setGetMenuSuccess({ data, menuId: id }));
    yield put(AttemptToGetStyles(data));
    yield put(attemptToGetOrderSections({ id }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenufailure(error));
  }
}

function* duplicateMenu({ payload }) {
  const { menuId, restName, restaurant_id } = payload;
  try {
    yield post(`v1/web_menu/${menuId}/duplicate/`);
    yield put(attemptToGetOnlineMenus({ id: restaurant_id, name: restName }));
    TOAST.success('Menu was successfully duplicated');
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenufailure(error));
  }
}
function* duplicateOrderingMenu({ payload }) {
  const { menuId, restName, restaurant_id } = payload;
  try {
    yield post(`v1/ordering_menu/${menuId}/duplicate/`);
    yield put(attemptToGetAllOrderingMenus({ id: restaurant_id, name: restName }));
    TOAST.success('Menu was successfully duplicated');
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenufailure(error));
  }
}
function* updateMenu({ payload }) {
  const { menuId, name, description, orderingMenu, selectedTypes, menuHours } = payload;
  const prefix = orderingMenu ? 'ordering' : 'web';
  try {
    const { data } = yield sendPut(`v1/${prefix}_menu/${menuId}`,
      { name, description, hours: menuHours, ...selectedTypes });
    yield put(setGetMenuSuccess({ data, menuId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenufailure(error));
  }
}

function* sortMenus({ payload }) {
  const { sortedMenus, online } = payload;
  try {
    const prefix = online ? 'web' : 'ordering';
    yield sendPut(`v1/${prefix}_menu/bulk/`, { menus: sortedMenus });
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenufailure(error));
  }
}

function* deleteMenu({ payload }) {
  const { menuId, restId, orderingMenu } = payload;
  const prefix = orderingMenu ? 'ordering' : 'web';
  try {
    yield sendDelete(`v1/${prefix}_menu/${menuId}`);
    const data = yield get(`v1/restaurant/${restId}/${prefix}_menus`);
    yield put(deleteMenuSuccess({ data, menuId, restId, orderingMenu }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* createMenu({ payload }) {
  const { name, restaurantId, description, changeRoute, orderingMenu } = payload;

  try {
    const newMenu = yield post(`v1/restaurant/${restaurantId}/${orderingMenu ? 'ordering' : 'web'}_menu/`, {
      name,
      description
    });
    yield put(setCreateMenuSuccess(newMenu));
    yield changeRoute(newMenu.data.id);
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setCreateMenufailure(error));
  }
}

function* editOrderingMenu({ payload }) {
  const { menuId, hidden, online } = payload;
  try {
    yield sendPut(`v1/${online ? 'web' : 'ordering'}_menu/${menuId}`, { hidden });
    yield put(hidePublishSuccess({ id: menuId, hidden, online }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* moveDish({ payload }) {
  const { id, menu_id, section_id, menuId } = payload;

  try {
    yield post(`v1/ordering_menu_section_dish/${id}/duplicate/`, {
      menu_id,
      section_id
    });
    yield put(attemptToRemoveDish({ id, menuId, orderingMenu: true }));
    yield put(attemptToGetOrderSections({ id: menuId }));
    TOAST.success('Move Succesffully');
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setCreateMenufailure(error));
  }
}
function* duplicateDish({ payload }) {
  const { id, menuId, sectionId } = payload;

  try {
    yield post(`v1/ordering_menu_section_dish/${id}/duplicate/`, {
      menu_id: +menuId,
      section_id: sectionId
    });
    yield put(attemptToGetOrderSections({ id: menuId }));
    TOAST.success('Duplicate Succesffully');
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setCreateMenufailure(error));
  }
}
function* menus<T>(): Iterable<T> {
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_GET_ONLINE_MENUS, getOnlineMenus);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_GET_MENU, getMenu);
  yield debounce(500, MENU_ACTIONS.ATTEMPT_TO_DUPLICATE_MENU, duplicateMenu);
  yield debounce(500, MENU_ACTIONS.ATTEMPT_TO_DUPLICATE_ORDERING_MENU, duplicateOrderingMenu);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_UPDATE_MENU, updateMenu);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_SORT_MENUS, sortMenus);
  yield takeLatest(MENU_ACTIONS.CREATE_MENU, createMenu);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_DELETE_MENU, deleteMenu);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_DOWNLOAD_MENU, downloadMenu);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_GET_ALL_ORDERING_MENUS, getAllOrderingMenus);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_GET_LIST_ORDERING_MENUS, getListOrderingMenus);
  yield takeLatest(MENU_ACTIONS.ATTEMPT_TO_GET_ORDERING_MENU, getOrderingMenu);
  yield takeLatest(MENU_ACTIONS.MOVE_DISH, moveDish);
  yield takeLatest(MENU_ACTIONS.DUPLICATE_DISH, duplicateDish);
  yield debounce(500, MENU_ACTIONS.ATTEMPT_TO_EDIT_MENU, editOrderingMenu);
}

export default menus;
