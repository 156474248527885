// @flow

import SimpleMenu from '../../common/components/svgIcons/SimpleMenu';
import ComplexMenu from '../../common/components/svgIcons/ComplexMenu';

import type { MenuType } from '../../types/menu';

export const ACTION_STATUSES = {
  PENDING: 'pending',
  SUCCEED: 'successful',
  FAILED: 'failed',
  UNDEFINED: undefined
};

export const INPUT_STATUSES = {
  VALID: 'valid',
  INVALID: 'invalid',
  UNDEFINED: undefined
};

export const DEFAULT_ERROR = 'Something went wrong';

export const MENU_UPLOADER_STEPS = [
  {
    label: 'Select Section Name',
    key: 'sectionName',
    step: 1
  },
  {
    label: 'Select All Section Areas',
    key: 'sections',
    step: 2
  },
  {
    label: 'Select Dish Name',
    key: 'name',
    step: 3
  },
  {
    label: 'Select Dish Description',
    key: 'description',
    step: 4
  },
  {
    label: 'Select Dish Price',
    key: 'price',
    step: 5
  }
];

export const MENU_SECTION_NAMES = {
  SECTION_NAME: 'sectionName',
  SECTION_DESCRIPTION: 'sectionDescription',
  DISH_NAME: 'name',
  DISH_DESCRIPTION: 'description',
  DISH_PRICE: 'price',
  DISH_OPTION_NAME: 'name',
  DISH_OPTION_PRICE: 'price',
  MODIFIER_GROUP_NAME: 'name'
};

export const MENU_DETAILS_NAMES = {
  NAME: 'menuName',
  DESCRIPTION: 'menuDescription',
  RESTAURANT_NAME: 'restaurantName'
};

export const SELECT_AREA_STEP: number = 2;
export const SELECT_AREA_STEP_NAME: string = 'sections';

export const IFRAME_EVENTS = {
  GET_IFRAME_ELEMENTS: 'getIframeElements',
  GET_IFRAME_ELEMENTS_RESPONSE: 'getIframeElementsResponse',
  START_SELECTION: 'startSelection',
  REMOVE_AREA: 'removeArea',
  SELECT_AREA: 'selectArea',
  SELECT_AREA_DATA: 'selectedAreaData',
  IFRAME_CLICK: 'iframeClick'
};

export const MENU_TEMPLATE_ID: string = 'menu-template';

export const AUTH_STORAGE_NAME: string = 'user';
export const ACTIVE_MENU: string = 'menu';

export const MENU_TOGGLES = {
  ONLINE: 'Online Menu',
  ORDERING: 'Ordering Menu',
  CATEGORY: 'Sync POS'
};


export const MENU_TYPE: Array<MenuType> = [
  {
    type: 'base',
    label: 'Simple',
    icon: SimpleMenu,
    // eslint-disable-next-line import/no-unresolved,global-require
    template: require('../../common/assets/images/basic-menu.png')
  }, {
    type: 'complex',
    label: 'Complex',
    icon: ComplexMenu,
    // eslint-disable-next-line import/no-unresolved,global-require
    template: require('../../common/assets/images/complex-menu.png')
  }
];

export const MENU_TYPE_FOR_DOWNLOAD = {
  YEXT: {
    label: 'YEXT',
    type: 'YEXT'
  },
  BBOT: {
    label: 'BBOT',
    type: 'BBOT'
  },
  TOAST: {
    label: 'TOAST',
    type: 'TOAST'
  }
};
