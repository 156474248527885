import { CONSTANTS } from '../config';

const removeDotAreaAndMergeCoordinatesToPriceArea = (group) => {
  const dottedDivIndex = group.find(data => CONSTANTS.SPACE_OR_DOT_REGEX.test(data.text));
  const priceDivIndex = group.find(data => CONSTANTS.PRICE_REGEX.test(data.text));

  if (dottedDivIndex && priceDivIndex) {
    const dottedDiv = group[group.indexOf(dottedDivIndex)];
    const priceDiv = group[group.indexOf(priceDivIndex)];

    if (dottedDiv !== -1 && priceDiv !== -1) {
      priceDiv.coordinates.left = Math.min(dottedDiv.coordinates.left, priceDiv.coordinates.left);
      priceDiv.coordinates.right = priceDiv.coordinates.left + priceDiv.coordinates.width;
    }
  }
};

export default removeDotAreaAndMergeCoordinatesToPriceArea;
