// flow
import type { BaseAction } from '../../common/types';

export const RESTAURANT_ACTIONS = {
  ATTEMPT_TO_GET_RESTAURANTS: '@RESTAURANT_ACTIONS/ATTEMPT_TO_GET_RESTAURANTS',
  ATTEMPT_TO_GET_CITIES: '@RESTAURANT_ACTIONS/ATTEMPT_TO_GET_CITIES',
  ATTEMPT_TO_GET_CUISINES: '@RESTAURANT_ACTIONS/ATTEMPT_TO_GET_CUISINES',
  ATTEMPT_TO_GET_NEIGHBORHOOD: '@RESTAURANT_ACTIONS/ATTEMPT_TO_GET_NEIGHBORHOOD',
  ATTEMPT_TO_GET_RESTAURANT: '@RESTAURANT_ACTIONS/ATTEMPT_TO_GET_RESTAURANT',
  SET_GET_RESTAURANTS_SUCCEED: '@RESTAURANT_ACTIONS/SET_GET_RESTAURANTS_SUCCEED',
  SET_GET_RESTAURANTS_FAILURE: '@RESTAURANT_ACTIONS/SET_GET_RESTAURANTS_FAILURE',
  ATTEMPT_TO_EDIT_RESTAURANT: '@RESTAURANT_ACTIONS/ATTEMPT_TO_EDIT_RESTAURANT',
  ATTEMPT_TO_DELETE_RESTAURANT: '@RESTAURANT_ACTIONS/ATTEMPT_TO_DELETE_RESTAURANT',
  CREATE_RESTAURANT_SUCCEED: '@RESTAURANT_ACTIONS/CREATE_RESTAURANT_SUCCEED',
  SET_GET_NEIGHBORHOOD_SUCCEED: '@RESTAURANT_ACTIONS/SET_GET_NEIGHBORHOOD_SUCCEED',
  SET_GET_CITIES_SUCCEED: '@RESTAURANT_ACTIONS/SET_GET_CITIES_SUCCEED',
  SET_GET_CUISINES_SUCCEED: '@RESTAURANT_ACTIONS/SET_GET_CUISINES_SUCCEED',
  SAVE_RESTAURANT_DATA: '@RESTAURANT_ACTIONS/SAVE_RESTAURANT_DATA',
  SET_GET_RESTAURANT_SUCCEED: '@RESTAURANT_ACTIONS/SET_GET_RESTAURANT_SUCCEED',
  ATTEMPT_TO_CREATE_RESTAURANT: '@RESTAURANT_ACTIONS/ATTEMPT_TO_CREATE_RESTAURANT'
};

export const attemptToGetRestaurants = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.ATTEMPT_TO_GET_RESTAURANTS,
  payload
});

export const attemptToGetCities = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.ATTEMPT_TO_GET_CITIES,
  payload
});

export const attemptToGetNeighborhood = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.ATTEMPT_TO_GET_NEIGHBORHOOD,
  payload
});

export const attemptToGetCuisines = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.ATTEMPT_TO_GET_CUISINES,
  payload
});

export const attemptToGetRestaurant = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.ATTEMPT_TO_GET_RESTAURANT,
  payload
});

export const setGetRestaurantsSuccess = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.SET_GET_RESTAURANTS_SUCCEED,
  payload
});

export const setGetCitiesSuccess = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.SET_GET_CITIES_SUCCEED,
  payload
});
export const setGetCuisinesSuccess = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.SET_GET_CUISINES_SUCCEED,
  payload
});

export const setGetNeighborhoodSuccess = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.SET_GET_NEIGHBORHOOD_SUCCEED,
  payload
});

export const setGetRestaurantSuccess = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.SET_GET_RESTAURANT_SUCCEED,
  payload
});

export const setCreateRestaurantSucceed = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.CREATE_RESTAURANT_SUCCEED,
  payload
});

export const setGetRestaurantsfailure = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.SET_GET_RESTAURANTS_FAILURE,
  payload
});

export const attemptToDeleteRestaurant = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.ATTEMPT_TO_DELETE_RESTAURANT,
  payload
});

export const attemptToEditRestaurant = (payload: Object): BaseAction => {
  return {
    type: RESTAURANT_ACTIONS.ATTEMPT_TO_EDIT_RESTAURANT,
    payload
  };
};

export const attemptToCreateRestaurant = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.ATTEMPT_TO_CREATE_RESTAURANT,
  payload
});

export const setSelectedRestaurant = (payload: Object): BaseAction => ({
  type: RESTAURANT_ACTIONS.SAVE_RESTAURANT_DATA,
  payload
});
