// flow
import { takeLatest, put } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { post } from '../../modules/api/request';
import { setSignInFailure, AUTH_ACTIONS } from '../actions/auth';
import { AUTH_STORAGE_NAME, ACTIVE_MENU } from '../../utils/constants';
import StorageManager from '../../utils/StorageManager';
import redirectToHomePage from '../../utils/redirectToHomePage';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const data = yield post('v1/login/', { email, password }, false);

    StorageManager.setItem(AUTH_STORAGE_NAME, data);
    StorageManager.setItem(ACTIVE_MENU, "Online Menu")
    redirectToHomePage();
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setSignInFailure(error));
  }
}

function* logout() {
  try {
    StorageManager.removeItem(AUTH_STORAGE_NAME);
    redirectToHomePage();
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setSignInFailure(error));
  }
}

function* signInSaga<T>(): Iterable<T> {
  yield takeLatest(AUTH_ACTIONS.ATTEMPT_TO_SIGN_IN, signIn);
  yield takeLatest(AUTH_ACTIONS.ATTEMPT_TO_LOG_OUT, logout);
}

export default signInSaga;
