// @flow
import React from 'react';

export default () => (
  <svg width="104" height="160" viewBox="0 0 104 160" fill="none" xmlns="http://www.w3.org/2000/svg" className="simple">
    <rect x="31.8089" width="32.628" height="4.50512" rx="0.270199" fill="#193A6E" />
    <rect x="18.7031" y="7.37183" width="58.7031" height="0.682594" rx="0.270199" fill="#193A6E" />
    <rect y="22.116" width="67.9864" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="124.505" width="67.9864" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="65.5291" width="67.9864" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="95.0171" width="67.9864" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="38.3618" width="77.1331" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="140.751" width="67.9864" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="81.7749" width="77.1331" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="111.263" width="67.9864" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="24.4368" width="103.072" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="126.826" width="103.072" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="67.8499" width="103.072" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="97.3379" width="103.072" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="40.6826" width="86.6894" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="143.072" width="103.072" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="84.0955" width="90.6485" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="113.584" width="73.8567" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="26.7578" width="62.5256" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="129.147" width="67.9864" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="70.1707" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="99.6587" width="74.6758" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="43.0034" width="77.1331" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="145.392" width="84.5051" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="54.6077" width="67.1672" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="156.997" width="62.5256" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="86.4165" width="62.5256" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="115.905" width="62.5256" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="29.0784" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="131.468" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="72.4915" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="101.979" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="45.3242" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="147.713" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="56.9282" width="67.1672" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="159.317" width="85.0512" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="31.3992" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="133.789" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="74.8123" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="104.301" width="89.9659" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="47.645" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="150.034" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="33.72" width="94.471" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="136.109" width="94.471" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="77.1331" width="94.471" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="106.621" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="49.9658" width="74.6758" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="152.355" width="94.471" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="36.041" width="94.471" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="138.43" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="79.4541" width="94.471" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="108.942" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="52.2869" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="154.676" width="83.2765" height="0.682594" rx="0.270199" fill="#526A8F" />
    <rect y="17.4741" width="32.628" height="1.36519" rx="0.270199" fill="#193A6E" />
    <rect y="119.864" width="32.628" height="1.36519" rx="0.270199" fill="#193A6E" />
    <rect y="60.8875" width="32.628" height="1.36519" rx="0.270199" fill="#193A6E" />
    <rect y="90.3755" width="32.628" height="1.36519" rx="0.270199" fill="#193A6E" />
  </svg>

);
