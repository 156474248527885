// flow
import type { BaseAction } from '../../common/types';

export const USER_ACTIONS = {
  ATTEMPT_TO_GET_USER: '@USER/ATTEMPT_TO_GET_USER',
  SET_USER_SUCCEED: '@USER/SET_USER_SUCCEED',
  ATTEMPT_TO_ADD_IMG: '@USER/ATTEMPT_TO_ADD_IMG',
  SET_USER_FAILURE: '@USER/SET_USER_FAILURE',
  ATTEMPT_TO_EDIT_USER: '@USER/ATTEMPT_TO_EDIT_USER',
  NEW_PASSWORD: '@USER/NEW_PASSWORD'
};

export const attemptToGetUser = (payload: Object): BaseAction => ({
  type: USER_ACTIONS.ATTEMPT_TO_GET_USER,
  payload
});

export const setUserSuccess = (payload: Object): BaseAction => ({
  type: USER_ACTIONS.SET_USER_SUCCEED,
  payload
});

export const setUserFailure = (payload: Object): BaseAction => ({
  type: USER_ACTIONS.SET_USER_FAILURE,
  payload
});

export const attemptToEditUser = (payload: Object): BaseAction => ({
  type: USER_ACTIONS.ATTEMPT_TO_EDIT_USER,
  payload
});

export const attemptToAddImg = (payload: Object): BaseAction => ({
  type: USER_ACTIONS.ATTEMPT_TO_ADD_IMG,
  payload
});

export const setNewPassword = (payload: Object): BaseAction => ({
  type: USER_ACTIONS.NEW_PASSWORD,
  payload
});
