import formatter from './date';

export type CategoryItemResponse = {
    _id: string,
    priority: number,
    category_name: string,
    bar_id: string,
    last_sync_date: {
      date:string
    }
};

export const toCategoryState = (data: CategoryItemResponse[]) => {
  return data.map(i => ({
    // eslint-disable-next-line no-underscore-dangle
    id: i._id,
    priority: i.priority,
    barId: i.bar_id,
    menuName: i.category_name,
    lastSyncedDate: i.last_sync_date ? formatter.format(new Date(i.last_sync_date.date)) : 'NA'
  }));
};
