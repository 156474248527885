// flow
import { takeLatest } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { post } from '../../modules/api/request';
import { ACCOUNT_RECOVER_ACTIONS } from '../actions/recoverAccount';
import redirectToHomePage from '../../utils/redirectToHomePage';

function* sendEmail({ payload }) {
  try {
    const { email } = payload;
    yield post('v1/reset_password/', { email, prefix_url: process.env.REACT_APP_URL_PREFIX }, false);
    redirectToHomePage();
  } catch (err) {
    TOAST.error('Something went wrong, please try again later');
  }
}

function* signUpSaga<T>(): Iterable<T> {
  yield takeLatest(ACCOUNT_RECOVER_ACTIONS.ATTEMPT_TO_SEND_EMAIL, sendEmail);
}

export default signUpSaga;
