// flow
import type { BaseAction } from '../../common/types';

export const MENU_ACTIONS = {
  ATTEMPT_TO_GET_ONLINE_MENUS: '@MENU_ACTIONS/ATTEMPT_TO_GET_ONLINE_MENUS',
  ATTEMPT_TO_GET_MENU: '@MENU_ACTIONS/ATTEMPT_TO_GET_MENU',
  ATTEMPT_TO_UPDATE_MENU: '@MENU_ACTIONS/ATTEMPT_TO_UPDATE_MENU',
  ATTEMPT_TO_SORT_MENUS: '@MENU_ACTIONS/ATTEMPT_TO_SORT_MENUS',
  ATTEMPT_TO_DELETE_MENU: '@MENU_ACTIONS/ATTEMPT_TO_DELETE_MENU',
  SET_GET_MENUS_SUCCEED: '@MENU_ACTIONS/SET_GET_MENUS_SUCCEED',
  SET_GET_MENU_SUCCEED: '@MENU_ACTIONS/SET_GET_MENU_SUCCEED',
  SET_GET_MENUS_FAILURE: '@MENU_ACTIONS/SET_GET_MENUS_FAILURE',
  SET_MENUS_STATUS: '@MENU_ACTIONS/SET_MENUS_STATUS',
  CREATE_MENU: '@MENU_ACTIONS/CREATE_MENU',
  DELETE_MENU_SUCCESS: '@DELETE_MENU_SUCCESS',
  ATTEMPT_TO_DUPLICATE_MENU: '@MENU_ACTIONS/ATTEMPT_TO_DUPLICATE_MENU',
  ATTEMPT_TO_DUPLICATE_ORDERING_MENU: '@MENU_ACTIONS/ATTEMPT_TO_DUPLICATE_ORDERING_MENU',
  SET_CREATE_MENU_SUCCEED: '@MENU_ACTIONS/SET_CREATE_MENU_SUCCEED',
  SET_CREATE_MENUS_FAILURE: '@MENU_ACTIONS/SET_CREATE_MENUS_FAILURE',
  ATTEMPT_TO_DOWNLOAD_MENU: '@MENU_ACTIONS/ATTEMPT_TO_DOWNLOAD_MENU',
  SET_GET_MENU_FAILURE: '@MENU_ACTIONS/SET_GET_MENU_FAILURE',
  ATTEMPT_TO_GET_ORDERING_MENU: '@MENU_ACTIONS/ATTEMPT_TO_GET_ORDERING_MENU',
  ATTEMPT_TO_GET_ALL_ORDERING_MENUS: '@MENU_ACTIONS/ATTEMPT_TO_GET_ALL_ORDERING_MENUS',
  ATTEMPT_TO_GET_LIST_ORDERING_MENUS: '@ATTEMPT_TO_GET_LIST_ORDERING_MENUS',
  ATTEMPT_TO_EDIT_MENU: '@MENU_ACTIONS/ATTEMPT_TO_EDIT_MENU',
  HIDE_PUBLISH_SUCCESS: '@MENU_ACTIONS/HIDE_PUBLISH_SUCCESS',
  MOVE_DISH: '@MENU_ACTIONS/MOVE_DISH',
  DUPLICATE_DISH: '@MENU_ACTIONS/DUPLICATE_DISH',
  CLEAR_ALL_MENUS: '@CLEAR_ALL_MENUS'
};

export const attemptToGetOnlineMenus = (payload: Object): BaseAction => {
  return {
    type: MENU_ACTIONS.ATTEMPT_TO_GET_ONLINE_MENUS,
    payload
  };
};

export const attemptToDuplicateMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_DUPLICATE_MENU,
  payload
});
export const attemptToDuplicateOrderingMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_DUPLICATE_ORDERING_MENU,
  payload
});
export const setMenusStatus = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.SET_MENUS_STATUS,
  payload
});

export const createNewMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.CREATE_MENU,
  payload
});

export const attemptToGetMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_GET_MENU,
  payload
});

export const attemptToGetOrderingMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_GET_ORDERING_MENU,
  payload
});
export const attemptToGetAllOrderingMenus = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_GET_ALL_ORDERING_MENUS,
  payload
});
export const attemptToGetListOrderingMenus = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_GET_LIST_ORDERING_MENUS,
  payload
});
export const attemptToUpdateMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_UPDATE_MENU,
  payload
});

export const attemptToSortMenus = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_SORT_MENUS,
  payload
});

export const attemptToDownloadMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_DOWNLOAD_MENU,
  payload
});

export const attemptToDeleteMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_DELETE_MENU,
  payload
});

export const deleteMenuSuccess = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.DELETE_MENU_SUCCESS,
  payload
});

export const setGetMenusSuccess = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.SET_GET_MENUS_SUCCEED,
  payload
});

export const setGetMenuSuccess = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.SET_GET_MENU_SUCCEED,
  payload
});

export const setCreateMenuSuccess = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.SET_CREATE_MENU_SUCCEED,
  payload
});

export const setGetMenusfailure = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.SET_GET_MENUS_FAILURE,
  payload
});

export const setCreateMenufailure = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.SET_CREATE_MENUS_FAILURE,
  payload
});

export const setGetMenufailure = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.SET_GET_MENU_FAILURE,
  payload
});

export const attemptToEditMenu = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.ATTEMPT_TO_EDIT_MENU,
  payload
});

export const hidePublishSuccess = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.HIDE_PUBLISH_SUCCESS,
  payload
});

export const attemptToMoveDish = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.MOVE_DISH,
  payload
});
export const attemptToDuplicateDish = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.DUPLICATE_DISH,
  payload
});

export const attemptToClearAllMenus = (payload: Object): BaseAction => ({
  type: MENU_ACTIONS.CLEAR_ALL_MENUS,
  payload
});
