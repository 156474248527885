// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { BANNER_ACTIONS } from '../actions/banner';

export type BannerState = {
  status: ?string,
  error: ?string,
};

const initialState = (): BannerState => ({
  status: null,
  error: null
});

// eslint-disable-next-line no-unused-vars
const ATTEMPT_TO_GET_BANNER_DATA = (state: BannerState) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED
});
const GET_BANNER_DATA = (state: BannerState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  bannerData: action.payload
});
const SET_BANNER_DATA = (state: BannerState) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED
});
const DELETE_BANNER_DATA = (state: BannerState) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED
});

export default (state: BannerState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case BANNER_ACTIONS.ATTEMPT_TO_GET_BANNER_DATA:
      return ATTEMPT_TO_GET_BANNER_DATA(state);
    case BANNER_ACTIONS.DELETE_BANNER_DATA:
      return DELETE_BANNER_DATA(state);
    case BANNER_ACTIONS.GET_BANNER_DATA:
      return GET_BANNER_DATA(state, action);
    case BANNER_ACTIONS.SET_BANNER_DATA:
      return SET_BANNER_DATA(state);

    default:
      return state;
  }
};
