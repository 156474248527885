import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import {
  ToastProvider,
  DefaultToastContainer
} from 'react-toast-notifications';

// TODO don't use this
// import import './App.scss';
// don't use styled components, if it's not the only solution
// import GlobalStyles from 'common/styles/globalStyle';

import Container from './containers';
import store from './store';

import './App.scss';

const history = createBrowserHistory();

/**
 * App Component
 * {Component Description}
 * @returns {React.Component} - App Component
 */

const CustomToastContainer = props => (
  // eslint-disable-next-line
  <DefaultToastContainer {...props} style={{ zIndex:
          9999
  }}
  />
);

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ToastProvider components={{ ToastContainer: CustomToastContainer }}>
          <Container />
        </ToastProvider>
      </Router>
    </Provider>
  );
}

export default App;
