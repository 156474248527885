import { put, takeLatest, delay } from 'redux-saga/effects';
import { post } from '../../modules/api/categories';
import {
  attemptToGetCategories,
  CATEGORY_ACTIONS,
  setGetCategoriesFailure,
  setGetCategoriesPending,
  setGetCategoriesSucceed,
  setSyncMenuItemFailure,
  setSyncMenuItemPending,
  setSyncMenuItemSucceed
} from '../actions/category';
import { toCategoryState } from '../../utils/formatter/category';
import TOAST from '../../modules/toastManager';

function* getCategories({ payload }) {
  const { easyTabRestName } = payload;
  yield delay(800);

  if (!easyTabRestName) {
    yield put(setGetCategoriesSucceed([]));
    return;
  }

  yield put(setGetCategoriesPending());
  try {
    const body = {
      page: payload.pageNumber,
      paginationCount: payload.pageSize,
      searchStr: payload.searchKeyword
    };
    const { data: { list, total } } = yield post(`ordering/${easyTabRestName}/getPOSCategoryList`, body);

    const data = toCategoryState(list);
    yield put(setGetCategoriesSucceed({ easyTabRestName, data, total }));
  } catch (error) {
    yield put(setGetCategoriesFailure(error));
  }
}

function* syncCategories({ payload }) {
  yield put(setGetCategoriesPending());
  const { easyTabRestName } = payload;
  if (!easyTabRestName) {
    yield put(setGetCategoriesSucceed([]));
  }
  try {
    yield post(`ordering/${easyTabRestName}/fetchPOSCategory`, {});
  } catch (error) {
    TOAST.success('Category Sync Failed');
  }
  yield put(attemptToGetCategories(payload));
}

function* syncMenuItem({ payload }) {
  yield put(setSyncMenuItemPending(payload));
  const { easyTabRestName } = payload;
  if (!easyTabRestName) {
    TOAST.error('This restaurant does not support Menu Syncing');
    return;
  }
  try {
    const { message } = yield post(`ordering/${easyTabRestName}/fetchPOSCustomProductBycategoryId`, {
      categoryId: payload.categoryId,
      menu_login_id: payload?.menu_login_id,
    });
    TOAST.success(message);
    yield put(setSyncMenuItemSucceed());
  } catch (error) {
    yield put(setSyncMenuItemFailure(error));
    TOAST.error('Menu Sync Failed');
  }
}

function* category<T>(): Iterable<T> {
  yield takeLatest(CATEGORY_ACTIONS.ATTEMPT_TO_GET_CATEGORIES, getCategories);
  yield takeLatest(CATEGORY_ACTIONS.ATTEMPT_TO_SYNC_CATEGORIES, syncCategories);
  yield takeLatest(CATEGORY_ACTIONS.ATTEMPT_TO_SYNC_MENU_ITEM, syncMenuItem);
}

export default category;
