// @flow
import { put, takeEvery } from 'redux-saga/effects';

import {
  MENU_UPLOADER_ACTIONS, setPublishMenuFailure
} from '../actions/menuUploader';
import TOAST from '../../modules/toastManager';
import { MENU_TYPE_FOR_DOWNLOAD } from '../../utils/constants';
import ExportToCSV from '../../utils/ExportToCSV';
import ExportToXLSX from '../../utils/ExportToXLSX';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

function* publishMenu({ payload: { menu, details, type } }) {
  try {
    switch (type) {
      case MENU_TYPE_FOR_DOWNLOAD.YEXT.type:
        ExportToCSV.yext({ details, menu });
        break;
      case MENU_TYPE_FOR_DOWNLOAD.BBOT.type:
        ExportToCSV.bbot({ details, menu });
        break;
      case MENU_TYPE_FOR_DOWNLOAD.TOAST.type:
        ExportToXLSX.toast({ details, menu });
        break;
      default: break;
    }
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setPublishMenuFailure(error));
  }
}

function* menuUploaderSaga<T>(): Iterable<T> {
  yield takeEvery(MENU_UPLOADER_ACTIONS.ATTEMPT_TO_PUBLISH_MENU, publishMenu);
}

export default menuUploaderSaga;
