// @flow
/**
 * @param err
 * @returns {*|{debug: string, details: string}}
 */
import { DEFAULT_ERROR } from '../constants';

const sagaErrorHandler = (err: any) => err?.response?.data?.error || DEFAULT_ERROR;

export default sagaErrorHandler;
