// @flow
import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import ErrorBoundary from '../common/components/ErrorBoundary';
import Loader from '../common/components/Loader';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes';
import TOAST from '../modules/toastManager';
import StorageManager from '../utils/StorageManager';
import { AUTH_STORAGE_NAME } from '../utils/constants';

export default () => {
  const { addToast } = useToasts();
  const user: boolean = StorageManager.getItem(AUTH_STORAGE_NAME);

  useEffect(() => {
    TOAST.setToastManager(addToast);
  }, [addToast]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Switch>
          {
            (user ? PRIVATE_ROUTES : PUBLIC_ROUTES).map((route) => (
              <Route
                exact={route.exact}
                key={route.key}
                path={`${route.path}`}
                component={route.component}
              />
            ))
          }
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};
