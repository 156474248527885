// flow
import type { BaseAction } from '../../common/types';

export const REGISTER_ACTIONS = {
  ATTEMPT_TO_SIGN_UP: '@AUTH/ATTEMPT_TO_SIGN_UP',
  SET_SIGN_UP_SUCCEED: '@AUTH/SET_SIGN_UP_SUCCEED',
  SET_SIGN_UP_FAILURE: '@AUTH/SET_SIGN_UP_FAILURE'
};

export const attemptToSignUp = (payload: Object): BaseAction => ({
  type: REGISTER_ACTIONS.ATTEMPT_TO_SIGN_UP,
  payload
});

export const setSignUpFailure = (payload: Object): BaseAction => ({
  type: REGISTER_ACTIONS.SET_SIGN_UP_FAILURE,
  payload
});
