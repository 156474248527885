/* eslint-disable camelcase */
// flow
import { takeLatest, put, debounce } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { get, sendDelete, put as sendPut, post } from '../../modules/api/request';
import {
  setGetRestaurantsSuccess,
  setGetRestaurantsfailure,
  attemptToGetRestaurants,
  setCreateRestaurantSucceed,
  setSelectedRestaurant,
  setGetRestaurantSuccess,
  setGetCitiesSuccess,
  setGetNeighborhoodSuccess,
  setGetCuisinesSuccess,
  RESTAURANT_ACTIONS
} from '../actions/restaurants';

import { attemptToGetAllOrderingMenus, attemptToGetOnlineMenus } from '../actions/menus';

import sagaErrorHandler from '../../utils/sagaErrorHandler';
import { ACTIVE_MENU, MENU_TOGGLES } from '../../utils/constants';
import StorageManager from '../../utils/StorageManager';

function* getAllRestaurants({ payload }) {
  const { id, name } = { ...payload };
  try {
    const data = yield get('v1/restaurants?includes=easy_tab');
    yield put(setGetRestaurantsSuccess(data));
    if (data.restaurants.length > 0) {
      const selected = id && data.restaurants.filter((rest) => rest.id === id);
      const restName = name || (selected && selected[0].name) || data.restaurants[0].name;
      const restId = id || data.restaurants[0].id;
      yield put(setSelectedRestaurant({ restId, restName }));
      // if(MENU_TOGGLES.ONLINE){
      if (StorageManager.getItem(ACTIVE_MENU)) {
        if (StorageManager.getItem(ACTIVE_MENU) && StorageManager.getItem(ACTIVE_MENU) == MENU_TOGGLES.ONLINE) {
          yield put(
            attemptToGetOnlineMenus({
              id: restId,
              name: restName
            })
          );
        } else {
          yield put(
            attemptToGetAllOrderingMenus({
              id: restId,
              name: restName
            })
          );
        }
      } else {
        yield put(
          attemptToGetOnlineMenus({
            id: restId,
            name: restName
          })
        );
      }
    }
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetRestaurantsfailure(error));
  }
}

function* getAllCities({ payload }) {
  const { value } = payload;
  try {
    const citiesList = yield get(`v1/cities/?q=${value}`);
    yield put(setGetCitiesSuccess(citiesList));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* getAllNeighborhoods({ payload }) {
  const { value, cityId } = payload;
  try {
    const neighborhoodList = yield get(`v1/neighborhoods/?city=${cityId}&q=${value}`);
    yield put(setGetNeighborhoodSuccess(neighborhoodList));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* getAllCuisines({ payload }) {
  const { value } = payload;
  try {
    const cuisinesList = yield get(`v1/cuisines/?q=${value}`);
    yield put(setGetCuisinesSuccess(cuisinesList));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* getRestaurant({ payload }) {
  const { id, changeRoute } = payload;
  try {
    const editableRestaurant = yield get(`v1/restaurant/${id}`);
    editableRestaurant.cuisines = editableRestaurant.cuisines.map(({ id, name }) => ({
      label: name,
      value: name,
      id
    }));
    editableRestaurant.neighborhoods = editableRestaurant.neighborhoods.map(({ id, name }) => ({
      label: name,
      value: name,
      id
    }));
    editableRestaurant.address = {
      label: editableRestaurant.address,
      value: editableRestaurant.address
    };
    yield put(setGetRestaurantSuccess(editableRestaurant));
    if (changeRoute) yield changeRoute(id);
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetRestaurantsfailure(error));
  }
}

function* editRestaurant({ payload }) {
  const {
    id,
    name,
    neighborhoods,
    cuisines,
    website,
    reserve_table_link,
    delivery_url,
    slug,
    phone,
    city,
    address,
    hours,
    coordinates,
    logo_url
  } = payload;
  const data = {
    name,
    slug: slug.toLowerCase(),
    website,
    reserve_table_link,
    delivery_url,
    neighborhoods: neighborhoods.length ? neighborhoods.map((value) => value.id) : [],
    cuisines: cuisines.length ? cuisines.map((value) => value.id) : [],
    phone,
    address: address?.value || address || '',
    ...(coordinates?.lat && { lat: coordinates.lat }),
    ...(coordinates?.lng && { lng: coordinates.lng }),
    city: city ? city.id : ''
  };
  try {
    const restaurant = yield sendPut(`v1/restaurant/${id}`, data);
    if (logo_url?.name) {
      const formData = new FormData();
      formData.append('image', logo_url);
      yield post(`v1/restaurant/${id}/logo/`, formData, false);
    }
    yield put(setCreateRestaurantSucceed(restaurant));
    yield post(`v1/restaurant/${id}/hours/`, hours);
    yield put(attemptToGetRestaurants({ id, name }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetRestaurantsfailure(error));
  }
}

function* deleteRestaurant({ payload }) {
  try {
    yield sendDelete(`v1/restaurant/${payload}`);
    yield put(attemptToGetRestaurants());
  } catch (err) {
    const error = sagaErrorHandler(err);

    TOAST.error(error);
    yield put(setGetRestaurantsfailure(error));
  }
}

function* createRestaurant({ payload }) {
  const {
    name,
    neighborhoods,
    cuisines,
    website,
    reserve_table_link,
    delivery_url,
    slug,
    phone,
    address,
    city,
    hours,
    coordinates,
    logo_url
  } = payload;
  const data = {
    name,
    slug: slug.toLowerCase(),
    website,
    reserve_table_link,
    delivery_url,
    neighborhoods: neighborhoods.map((value) => value.id),
    cuisines: cuisines.map((value) => value.id),
    phone,
    address: address?.value || address || '',
    lat: coordinates.lat,
    lng: coordinates.lng,
    city: city.id,
    logo_url
  };
  try {
    const restaurant = yield post('v1/restaurant/', data);
    if (logo_url) {
      const formData = new FormData();
      formData.append('image', logo_url);
      yield post(`v1/restaurant/${restaurant.data.id}/logo/`, formData, false);
    }
    yield put(setCreateRestaurantSucceed(restaurant));
    yield post(`v1/restaurant/${restaurant.data.id}/hours/`, hours);
    yield put(attemptToGetRestaurants({ id: restaurant.data.id, name }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetRestaurantsfailure(error));
  }
}

function* restaurantsSaga<T>(): Iterable<T> {
  yield takeLatest(RESTAURANT_ACTIONS.ATTEMPT_TO_GET_RESTAURANTS, getAllRestaurants);
  yield debounce(500, RESTAURANT_ACTIONS.ATTEMPT_TO_GET_CITIES, getAllCities);
  yield debounce(500, RESTAURANT_ACTIONS.ATTEMPT_TO_GET_NEIGHBORHOOD, getAllNeighborhoods);
  yield debounce(500, RESTAURANT_ACTIONS.ATTEMPT_TO_GET_CUISINES, getAllCuisines);
  yield takeLatest(RESTAURANT_ACTIONS.ATTEMPT_TO_GET_RESTAURANT, getRestaurant);
  yield takeLatest(RESTAURANT_ACTIONS.ATTEMPT_TO_DELETE_RESTAURANT, deleteRestaurant);
  yield takeLatest(RESTAURANT_ACTIONS.ATTEMPT_TO_EDIT_RESTAURANT, editRestaurant);
  yield takeLatest(RESTAURANT_ACTIONS.ATTEMPT_TO_CREATE_RESTAURANT, createRestaurant);
}

export default restaurantsSaga;
