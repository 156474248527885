const exportToBBOTCSV = (data = [], params = {
  MenuInternalName: 'N/A'
}) => {
  const csvTitles = [
    'FOOD OR DRINK',
    'CATEGORY',
    'TITLE',
    'DESCRIPTION',
    'PRICE',
    'MOST LOVED',
    'REPORT CATEGORY',
    'DIETARY TAGS (OPTIONAL)',
    'BBOT ID',
    'IMAGE (OPTIONAL)',
    'SHOW IMAGE ON FRONT PAGE',
    'ADD-ON HEADING',
    'NUM SELECTABLE IN THIS HEADING',
    'ADD-ON NAME',
    'ADD-ON PRICE',
    'ADD-ON SELECTED BY DEFAULT'
  ];
  const csvData = [csvTitles];
  const emptyText: string = '';
  const emptyColumnText: string = '';
  const emptyTextArray = new Array(8).map(() => emptyColumnText);

  for (let i = 0; i < data.length; i++) {
    const { items, text } = data[i];
    for (let j = 0; j < items.length; j++) {
      const {
        text: dishName, price, options, description: dishDescription
      } = items[j];

      if (options && Array.isArray(options) && options.length > 0) {
        for (let k = 0; k < options.length; k++) {
          let optionRow;
          if (k === 0) {
            optionRow = [
              emptyText,
              text,
              `"${dishName}"`,
              `"${dishDescription}"`,
              price,
              ...emptyTextArray
            ];
          } else {
            optionRow = new Array(13).map(() => '');
          }
          optionRow.push(
            `"${options[k].name}"`,
            `"${options[k].price}"`,
            k === 0 ? emptyColumnText : ''
          );
          csvData.push(optionRow);
        }
      } else {
        csvData.push([
          emptyText,
          j === 0 ? text : '',
          `"${dishName}"`,
          `"${dishDescription}"`,
          price,
          ...emptyTextArray,
          '',
          '',
          emptyColumnText
        ]);
      }
    }
  }
  const content = `data:text/csv;charset=utf-8,${csvData.map(e => e.join(',')).join('\n')}`;

  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${params.MenuInternalName}.BBOT.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
};

export default exportToBBOTCSV;
