// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { MENUS_ACTIONS } from '../actions/sections';

export type MenusState = {
  status: ?string,
  error: ?string,
  data: ?any
};

const initialState = (): MenusState => ({
  status: null,
  error: null,
  data: []
});

const ATTEMPT_TO_GET_MENUS = (state: MenusState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

// eslint-disable-next-line no-unused-vars
const SET_GET_MENU_SECTIONS_SUCCEED = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  data: action.payload
});

const SET_SECTION_STATUS = (state: MenusState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

const SET_GET_RMENU_SECTIONS_FAILURE = (state: MenusState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});

export default (state: MenusState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case MENUS_ACTIONS.ATTEMPT_TO_GET_MENUS:
      return ATTEMPT_TO_GET_MENUS(state);
    case MENUS_ACTIONS.SET_GET_MENU_SECTIONS_SUCCEED:
      return SET_GET_MENU_SECTIONS_SUCCEED(state, action);
    case MENUS_ACTIONS.SET_SECTION_STATUS:
      return SET_SECTION_STATUS(state);
    case MENUS_ACTIONS.SET_GET_RMENU_SECTIONS_FAILURE:
      return SET_GET_RMENU_SECTIONS_FAILURE(state, action);
    default:
      return state;
  }
};
