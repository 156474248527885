import getDistance from './get-distance';

const getMinDistance = (arr, arr1) => {
  return Math.min(
    ...arr1.map(({ x, y }) => {
      return Math.min(
        ...arr.map(({ x: x2, y: y2 }) => {
          return getDistance(x, x2, y, y2);
        })
      );
    })
  );
};

export default getMinDistance;
