// @flow
import type { BaseAction } from '../../common/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { RESTAURANT_ACTIONS } from '../actions/restaurants';

export type RestaurantsState = {
  status: ?string,
  error: ?string,
  data: any[],
  newRestaurant: Object,
  citiesList: Object[],
  neighborhoodsList: Object[],
  cuisinesList: Object[],
  editableRestaurant: Object,
  selectedRestaurant: Object
};

const initialState = (): RestaurantsState => ({
  status: null,
  error: null,
  data: [],
  selectedRestaurant: {},
  editableRestaurant: {},
  newRestaurant: {},
  citiesList: [],
  neighborhoodsList: [],
  cuisinesList: []
});

const ATTEMPT_TO_GET_RESTAURANTS = (state: RestaurantsState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

const ATTEMPT_TO_EDIT_RESTAURANT = (state: RestaurantsState) => ({
  ...state,
  status: ACTION_STATUSES.PENDING
});

// eslint-disable-next-line no-unused-vars
const SET_GET_RESTAURANTS_SUCCEED = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  data: action.payload
});

const CREATE_RESTAURANT_SUCCEED = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  newRestaurant: action.payload && action.payload.data
});

const SAVE_RESTAURANT_DATA = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  selectedRestaurant: action.payload
});

const SET_GET_RESTAURANT_SUCCEED = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  editableRestaurant: action.payload
});

const SET_GET_CITIES_SUCCEED = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  citiesList: action.payload && action.payload.cities
});

const SET_GET_NEIGHBORHOOD_SUCCEED = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  neighborhoodsList: action.payload && action.payload.neighborhoods
});

const SET_GET_CUISINES_SUCCEED = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.SUCCEED,
  cuisinesList: action.payload && action.payload.cuisines
});

const SET_GET_RESTAURANTS_FAILURE = (state: RestaurantsState, action: BaseAction) => ({
  ...state,
  status: ACTION_STATUSES.FAILED,
  error: action.payload
});

export default (state: RestaurantsState = initialState(), action: BaseAction) => {
  switch (action.type) {
    case RESTAURANT_ACTIONS.ATTEMPT_TO_GET_RESTAURANTS:
      return ATTEMPT_TO_GET_RESTAURANTS(state);
    case RESTAURANT_ACTIONS.ATTEMPT_TO_EDIT_RESTAURANT:
      return ATTEMPT_TO_EDIT_RESTAURANT(state);
    case RESTAURANT_ACTIONS.SET_GET_RESTAURANTS_SUCCEED:
      return SET_GET_RESTAURANTS_SUCCEED(state, action);
    case RESTAURANT_ACTIONS.SET_GET_CITIES_SUCCEED:
      return SET_GET_CITIES_SUCCEED(state, action);
    case RESTAURANT_ACTIONS.SET_GET_NEIGHBORHOOD_SUCCEED:
      return SET_GET_NEIGHBORHOOD_SUCCEED(state, action);
    case RESTAURANT_ACTIONS.SET_GET_CUISINES_SUCCEED:
      return SET_GET_CUISINES_SUCCEED(state, action);
    case RESTAURANT_ACTIONS.CREATE_RESTAURANT_SUCCEED:
      return CREATE_RESTAURANT_SUCCEED(state, action);
    case RESTAURANT_ACTIONS.SET_GET_RESTAURANTS_FAILURE:
      return SET_GET_RESTAURANTS_FAILURE(state, action);
    case RESTAURANT_ACTIONS.SAVE_RESTAURANT_DATA:
      return SAVE_RESTAURANT_DATA(state, action);
    case RESTAURANT_ACTIONS.SET_GET_RESTAURANT_SUCCEED:
      return SET_GET_RESTAURANT_SUCCEED(state, action);
    default:
      return state;
  }
};
