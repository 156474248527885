/* eslint-disable no-param-reassign */
import { CONSTANTS } from '../config';

const getDishPriceFromDescription = (dish) => {
  const regex = CONSTANTS.PRICE_END_OF_THE_STRING_REGEX;

  const dishNameWithPriceMatch = !dish.dishPrice && !dish.dishDescription && dish?.dishName?.match(regex);
  const dishDescriptionWithPriceMatch = !dish?.dishPrice && dish?.dishDescription?.match(regex);

  if (dishNameWithPriceMatch) {
    dish.dishPrice = dish.dishName.slice(dishNameWithPriceMatch.index);
    dish.dishName = dish.dishName.slice(0, dishNameWithPriceMatch.index);
  }

  if (dishDescriptionWithPriceMatch) {
    dish.dishPrice = dish.dishDescription.slice(dishDescriptionWithPriceMatch.index);
    dish.dishDescription = dish.dishDescription.slice(0, dishDescriptionWithPriceMatch.index);
  }
};

export default getDishPriceFromDescription;
