// flow
import type { BaseAction } from '../../common/types';

export const GOOGLE_ACTIONS = {
  ATTEMPT_TO_GET_GOOGLE_FONTS: '@GOOGLE_ACTIONS/ATTEMPT_TO_GET_GOOGLE_FONTS',
  ATTEMPT_TO_GET_GOOGLE_FONTS_DEBOUNCE: '@GOOGLE_ACTIONS/ATTEMPT_TO_GET_GOOGLE_FONTS_DEBOUNCE',
  SET_GET_GOOGLE_FONTS_SUCCEED: '@GOOGLE_ACTIONS/SET_GET_GOOGLE_FONTS_SUCCEED'
};

export const attemptToGetGoogleFonts = (payload: Object): BaseAction => ({
  type: GOOGLE_ACTIONS.ATTEMPT_TO_GET_GOOGLE_FONTS,
  payload
});

export const attemptToGetGoogleFontsDebounce = (payload: Object): BaseAction => ({
  type: GOOGLE_ACTIONS.ATTEMPT_TO_GET_GOOGLE_FONTS_DEBOUNCE,
  payload
});

export const setGetGoogleFontsSuccess = (payload: Object): BaseAction => ({
  type: GOOGLE_ACTIONS.SET_GET_GOOGLE_FONTS_SUCCEED,
  payload
});
