// // flow
import type { BaseAction } from '../../common/types';

export const CATEGORY_ACTIONS = {
  ATTEMPT_TO_GET_CATEGORIES: '@ATTEMPT_TO_GET_CATEGORIES',
  SET_GET_CATEGORIES_PENDING: '@SET_GET_CATEGORIES_PENDING',
  SET_GET_CATEGORIES_SUCCEED: '@SET_GET_CATEGORIES_SUCCEED',
  SET_GET_CATEGORIES_FAILURE: '@SET_CATEGORIES_FAILURE',
  ATTEMPT_TO_SYNC_CATEGORIES: '@ATTEMPT_TO_SYNC_CATEGORIES',
  ATTEMPT_TO_SYNC_MENU_ITEM: '@ATTEMPT_TO_SYNC_MENU_ITEM',
  SET_SYNC_MENU_ITEM_PENDING: '@SET_SYNC_MENU_ITEM_PENDING',
  SET_SYNC_MENU_ITEM_SUCCEED: '@SET_SYNC_MENU_ITEM_SUCCEED',
  SET_SYNC_MENU_ITEM_FAILURE: '@SET_SYNC_MENU_ITEM_FAILURE'
};

export const attemptToGetCategories = (payload: Object): BaseAction => {
  return {

    type: CATEGORY_ACTIONS.ATTEMPT_TO_GET_CATEGORIES,
    payload
  };

};

export const attemptToSyncCategories = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.ATTEMPT_TO_SYNC_CATEGORIES,
    payload
  };
};

export const attemptToSyncMenuItem = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.ATTEMPT_TO_SYNC_MENU_ITEM,
    payload
  };
};
export const setGetCategoriesPending = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.SET_GET_CATEGORIES_PENDING,
    payload
  };
};

export const setGetCategoriesSucceed = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.SET_GET_CATEGORIES_SUCCEED,
    payload
  };
};

export const setGetCategoriesFailure = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.SET_GET_CATEGORIES_FAILURE,
    payload
  };
};

export const setSyncMenuItemPending = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.SET_SYNC_MENU_ITEM_PENDING,
    payload
  };
};

export const setSyncMenuItemSucceed = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.SET_SYNC_MENU_ITEM_SUCCEED,
    payload
  };
};

export const setSyncMenuItemFailure = (payload: Object): BaseAction => {
  return {
    type: CATEGORY_ACTIONS.SET_SYNC_MENU_ITEM_FAILURE,
    payload
  };
};
