// flow
import { takeLatest, put, debounce } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { get } from '../../modules/api/request';
import {
  GOOGLE_ACTIONS,
  setGetGoogleFontsSuccess
} from '../actions/googleFonts';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

function* getGoogleFonts({ payload }) {
  try {
    const googleFonts = yield get(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_GOOGLE_FONTS_URL}?key=${process.env.REACT_APP_GOOGLE_FONTS_API_KEY}&offset=${payload?.offset || 0}&search=${payload?.name || ''}`,
      null,
      false,
      false
    );
    yield put(setGetGoogleFontsSuccess({ googleFonts: googleFonts.items }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* menus<T>(): Iterable<T> {
  yield takeLatest(GOOGLE_ACTIONS.ATTEMPT_TO_GET_GOOGLE_FONTS, getGoogleFonts);
  yield debounce(500, GOOGLE_ACTIONS.ATTEMPT_TO_GET_GOOGLE_FONTS_DEBOUNCE, getGoogleFonts);
}

export default menus;
