import { fork, put, takeLatest } from 'redux-saga/effects';
import sagaErrorHandler from '../../utils/sagaErrorHandler';
import {
  attemptToSignInGMBSuccess,
  getGMBAccountsSuccess,
  getGMBLocationsSuccess,
  GMB_ACTIONS
} from '../actions/gmb';
import TOAST from '../../modules/toastManager';
import { get, post } from '../../modules/api/request';
import { apiConfig } from '../../config';

function* signInGMB({ payload }) {
  try {
    const { code } = payload;
    const data = yield get('v1/google_gmb_callback/', { code, redirect_uri: apiConfig.GOOGLE_REDIRECT_URI }, false);
    yield put(attemptToSignInGMBSuccess({ data }));
    yield fork(getGMBAccounts, data);
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    // yield put(setSignInFailure(error));
  }
}
function* getGMBAccounts({ access_token, refresh_token }) {
  try {
    const accounts = yield get('v1/gmb/accounts', { token: access_token });
    yield put(getGMBAccountsSuccess({ accounts, access_token, refresh_token }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}
function* getGMBLocations({ payload }) {
  try {
    const locations = yield get('v1/gmb/locations', { account: payload.name, token: payload.token });
    yield put(getGMBLocationsSuccess(locations));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* sendMenuToGMB({ payload }) {
  const { account_name, location_name, token, rest_id, refreshToken } = payload;
  try {
    yield post(`v1/restaurant/${rest_id}/gmb/credentials/`,
      { token, account: account_name, location: location_name, refresh_token: refreshToken });
    TOAST.success('GMB credentials have been successfully synchronized.');
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}
function* syncSingleMenuToGMB({ payload }) {
  try {
    yield post(`v1/web_menu/${payload.menuId}/publish_to_google/`);
    TOAST.success('Menu successfully synced');
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}
function* gmbSaga<T>(): Iterable<T> {
  yield takeLatest(GMB_ACTIONS.ATTEMPT_TO_SIGN_IN_GMB, signInGMB);
  yield takeLatest(GMB_ACTIONS.ATTEMPT_TO_GET_GMB_ACCOUNTS, getGMBAccounts);
  yield takeLatest(GMB_ACTIONS.ATTEMPT_TO_GET_GMB_LOCATIONS, getGMBLocations);
  yield takeLatest(GMB_ACTIONS.SYNC_MENU_TO_GMB, sendMenuToGMB);
  yield takeLatest(GMB_ACTIONS.SYNC_SINGLE_MENU_TO_GMB, syncSingleMenuToGMB);
}

export default gmbSaga;
