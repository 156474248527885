/**
 * @param {Binary} file - file
 * @param {string} signedRequest - signedRequest
 * @param {string} method - method
 * @returns {Promise<any>} -
 */
const uploadFile = (file, signedRequest, method = 'PUT') => new Promise((res, rej) => {
  const xhr = new XMLHttpRequest();
  xhr.open(method, signedRequest);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        res(200);
      } else {
        rej(xhr);
      }
    }
  };
  xhr.setRequestHeader('Content-Type', 'application/octet-stream');
  xhr.send(file);
});

export default uploadFile;
