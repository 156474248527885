const setMultipleSectionNameScoreToZero = (sectionNames) => {
  sectionNames.forEach(group => {
    if (group?.length) {
      group.forEach((item, index) => {
        if (index != 0) {
          // eslint-disable-next-line no-param-reassign
          item.sectionNameScore = 0;
        }
      });
    }
  });
};

export default setMultipleSectionNameScoreToZero;
