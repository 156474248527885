import { combineReducers } from 'redux';

import media from './media';
import onBoarding from './onBoarding';
import menuUploader from './menuUploader';
import auth from './auth';
import menuBuilder from './menuBuilder';
import sections from './sections';
import restaurants from './restaurants';
import menus from './menus';
import user from './user';
import googleFonts from './googleFonts';
import customization from './customization';
import category from './category';
import gmb from './gmb';
import banner from './banner';

export default combineReducers({
  media,
  onBoarding,
  menuUploader,
  menuBuilder,
  sections,
  restaurants,
  menus,
  user,
  googleFonts,
  auth,
  customization,
  category,
  gmb,
  banner
});
