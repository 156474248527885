import { v4 as uuidv4 } from 'uuid';

export function transformMainInfo(menuInfo, restName) {
  return {
    menuDescription: menuInfo.description,
    menuName: menuInfo.name,
    menuType: 'base',
    restaurantId: menuInfo.restaurantId || '',
    restaurantName: menuInfo.restaurantName || restName || ''
  };
}

export function transformSections(menuInfo) {
  const sections = menuInfo.sections.map((section) => {
    const dishes = section.section_dishes.map((dish) => ({
      dishName: dish.name,
      dishDescription: dish.description,
      dishPrice: dish.price,
      dishOptions: dish.options
    }));
    return {
      sectionName: section.name,
      dishList: dishes
    };
  });
  return sections;
}

export function menuDownloadSections(sections) {
  const transformed = {};
  for (let i = 0; i < sections.length; i++) {
    transformed[uuidv4()] = sections[i];
  }
  return transformed;
}
