const setCoordinates = (coordinates1, coordinates2) => {
  return {
    left: Math.min(coordinates1.left, coordinates2.left),
    right: Math.max(coordinates1.right, coordinates2.right),
    top: Math.min(coordinates1.top, coordinates2.top),
    bottom: Math.max(coordinates1.bottom, coordinates2.bottom)
  };
};

export default setCoordinates;
