// @flow
import React from 'react';

export default () => (
  <svg
    width="104"
    height="160"
    viewBox="0 0 104 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="complex"
  >
    <rect x="36.1619" y="13.9014" width="32.5724" height="1.36286" rx="0.255537" fill="#C5C5C5" />
    <rect x="36.1619" y="16.218" width="32.5724" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="36.2981" width="32.5724" height="4.49744" rx="0.255537" fill="#C5C5C5" />
    <rect x="70.7786" y="1.90796" width="25.6218" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="8.3595" y="1.90796" width="26.0307" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect
      x="62.874"
      y="78.228"
      width="20.8518"
      height="1.36286"
      rx="0.255537"
      transform="rotate(180 62.874 78.228)"
      fill="#C5C5C5"
    />
    <rect x="42.0222" y="34.8894" width="20.8518" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="42.0222" y="32.8447" width="20.8518" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="42.0222" y="28.7563" width="20.8518" height="1.36286" rx="0.255537" fill="#C5C5C5" />
    <rect x="42.0222" y="37.0698" width="20.8518" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="42.0222" y="39.1143" width="20.8518" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="27.3936" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="131.107" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="96.6267" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="96.6267" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="96.6267" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="81.6355" width="20.5792" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="50.8347" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="131.107" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="81.6355" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="131.107" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.8484" y="81.6355" width="20.5792" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="50.8347" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="131.107" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="81.6355" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="71.0511" y="27.3936" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="20.6252" y="27.3936" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="86.4514" y="27.3936" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="31.6184" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="135.332" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="100.852" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="100.852" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="100.852" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="85.8604" width="20.5792" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="55.0596" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="135.332" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="85.8604" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="135.332" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.8484" y="85.8604" width="20.5792" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="55.0596" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="135.332" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="85.8604" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="71.0511" y="31.6184" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="20.6252" y="31.6184" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="86.4514" y="31.6184" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="39.2502" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="150.869" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="159.319" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="109.302" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="109.302" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="109.302" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="63.6455" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="150.869" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="159.319" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="150.869" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="159.319" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="63.6455" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="150.869" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="159.319" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="117.751" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="117.751" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="117.751" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="72.0955" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="72.0955" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="71.0511" y="39.2502" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="20.6252" y="39.2502" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="86.4514" y="39.2502" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="29.574" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="133.152" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="98.8076" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="98.8076" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="98.8076" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="83.6797" width="20.5792" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="53.0151" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="133.152" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="83.6797" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="133.152" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.8484" y="83.6797" width="20.5792" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="53.0151" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="133.152" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="83.6797" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="71.0511" y="29.574" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="20.6252" y="29.574" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="86.4514" y="29.574" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="35.0256" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="146.644" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="155.094" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="105.077" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="105.077" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="105.077" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="59.4209" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="146.644" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="155.094" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="146.644" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="155.094" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="59.4209" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="146.644" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="155.094" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="113.527" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="113.527" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="113.527" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="67.8706" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="67.8706" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="71.0511" y="35.0256" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="20.6252" y="35.0256" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="86.4514" y="35.0256" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="37.2063" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="148.688" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="157.138" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="107.257" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="107.257" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="107.257" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="61.4651" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="148.688" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="157.138" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="148.688" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="157.138" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="61.4651" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="148.688" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="157.138" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="115.707" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="38.7512" y="115.707" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="72.4139" y="115.707" width="26.9847" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="56.8773" y="69.9148" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22491" y="50.8347" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="50.8347" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="55.0596" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="55.0596" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="63.6455" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="63.6455" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="72.0955" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="72.0955" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="53.0151" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="53.0151" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="59.4209" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="59.4209" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="67.8706" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="67.8706" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="61.4651" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="61.4651" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="5.22485" y="69.9148" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="27.5758" y="69.9148" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="79.2283" y="69.9148" width="20.1704" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="71.0511" y="37.2063" width="13.0835" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="20.6252" y="37.2063" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="86.4514" y="37.2063" width="13.2198" height="0.681431" rx="0.255537" fill="#E5E5E5" />
    <rect x="48.4276" y="92.811" width="8.04089" height="1.09029" rx="0.255537" fill="#C5C5C5" />
    <rect x="48.4276" y="126.61" width="8.04089" height="1.36286" rx="0.255537" fill="#C5C5C5" />
    <rect x="48.4276" y="140.102" width="8.04089" height="1.36286" rx="0.255537" fill="#C5C5C5" />
    <rect x="48.4276" y="22.896" width="8.04089" height="1.36286" rx="0.255537" fill="#C5C5C5" />
    <rect x="22.5332" y="46.4736" width="8.04089" height="1.36286" rx="0.255537" fill="#C5C5C5" />
    <rect x="74.322" y="46.4736" width="8.04089" height="1.36286" rx="0.255537" fill="#C5C5C5" />
    <path
      // eslint-disable-next-line max-len
      d="M64.7819 77.5469H103.641C103.782 77.5469 103.896 77.6613 103.896 77.8024V122.266C103.896 122.407 103.782 122.521 103.641 122.521H1.25554C1.11441 122.521 1 122.407 1 122.266V77.8024C1 77.6613 1.11441 77.5469 1.25554 77.5469H40.1059"
      stroke="#E5E5E5"
      strokeWidth="0.127768"
    />
    <rect
      x="1"
      y="11.9932"
      width="102.896"
      height="6.81431"
      rx="0.255537"
      stroke="#E5E5E5"
      strokeWidth="0.127768"
    />
    <rect
      x="38.7512"
      y="26.4392"
      width="27.3935"
      height="15.6729"
      rx="0.255537"
      stroke="#E5E5E5"
      strokeWidth="0.127768"
    />
    <line x1="52.3161" y1="73.4583" x2="52.3161" y2="50.8347" stroke="#E5E5E5" strokeWidth="0.127768" />
    <line x1="46.5196" y1="23.6415" x2="1" y2="23.6415" stroke="#E5E5E5" strokeWidth="0.127768" />
    <line x1="20.6252" y1="47.2192" x2="0.999971" y2="47.2192" stroke="#E5E5E5" strokeWidth="0.127768" />
    <line x1="103.896" y1="47.2192" x2="84.2709" y2="47.2192" stroke="#C5C5C5" strokeWidth="0.127768" />
    <line x1="72.4139" y1="47.2192" x2="32.4821" y2="47.2192" stroke="#E5E5E5" strokeWidth="0.127768" />
    <line x1="103.896" y1="23.6415" x2="58.3765" y2="23.6415" stroke="#E5E5E5" strokeWidth="0.127768" />
    <line x1="5.36115" y1="90.8389" x2="99.5349" y2="90.839" stroke="#E5E5E5" strokeWidth="0.127768" />
  </svg>
);
