// flow
import type { BaseAction } from '../../common/types';

export const MENUS_ACTIONS = {
  ATTEMPT_TO_GET_MENU_SECTIONS: '@MENUS_ACTIONS/ATTEMPT_TO_GET_MENU_SECTIONS',
  ATTEMPT_TO_GET_ORDER_SECTIONS: '@MENUS_ACTIONS/ATTEMPT_TO_GET_ORDER_SECTIONS',
  SET_SECTION_STATUS: '@MENUS_ACTIONS/SET_SECTION_STATUS',
  SET_GET_MENU_SECTIONS_SUCCEED: '@MENUS_ACTIONS/SET_GET_MENU_SECTIONS_SUCCEED'
};

export const attemptToGetSections = (payload: Object): BaseAction => ({
  type: MENUS_ACTIONS.ATTEMPT_TO_GET_MENU_SECTIONS,
  payload
});

export const attemptToGetOrderSections = (payload: Object): BaseAction => ({
  type: MENUS_ACTIONS.ATTEMPT_TO_GET_ORDER_SECTIONS,
  payload
});

export const setMenuSectionsStatus = (payload: Object): BaseAction => ({
  type: MENUS_ACTIONS.SET_SECTION_STATUS,
  payload
});

export const setGetMenuSectionsSuccess = (payload: Object): BaseAction => ({
  type: MENUS_ACTIONS.SET_GET_MENU_SECTIONS_SUCCEED,
  payload
});

export const setGetMenuSectionsFailure = (payload: Object): BaseAction => ({
  type: MENUS_ACTIONS.SET_GET_RMENU_SECTIONS_FAILURE,
  payload
});
