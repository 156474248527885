class StorageManager {
  static setItem = (key, data) => {
    try {
      const serializedState = JSON.stringify(data);
      localStorage.setItem(key, serializedState);
    } catch {
      // ignore write errors
    }
  };

  static getItem = (key) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return JSON.parse(serializedState);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  static removeItem = (key) => {
    localStorage.removeItem(key);
  }
}

export default StorageManager;
