// flow
import type { BaseAction } from '../../common/types';

export const MENU_BUILDER_ACTIONS = {
  ATTEMPT_TO_CREATE_SECTION: '@MENU_BUILDER_ACTIONS/ATTEMPT_TO_CREATE_SECTION',
  ATTEMPT_TO_CREATE_DISH: '@MENU_BUILDER_ACTIONS/ATTEMPT_TO_CREATE_DISH',
  SET_CREATE_SECTION_SUCCEED: '@MENU_BUILDER_ACTIONS/SET_CREATE_SECTION_SUCCEED',
  DELETE_SECTION: '@MENU_BUILDER_ACTIONS/DELETE_SECTION',
  EDIT_SECTION: '@MENU_BUILDER_ACTIONS/EDIT_SECTION',
  EDIT_SECTION_ORDER: '@MENU_BUILDER_ACTIONS/EDIT_SECTION_ORDER',
  EDIT_DISH_ORDER: '@MENU_BUILDER_ACTIONS/EDIT_DISH_ORDER',
  SET_CREATE_SECTION_FAILURE: '@MENU_BUILDER_ACTIONS/SET_CREATE_SECTION_FAILURE',
  SET_GET_RESTAURANTS_FAILURE: '@MENU_BUILDER_ACTIONS/SET_GET_RESTAURANTS_FAILURE',
  DELETE_DISH: '@MENU_BUILDER_ACTIONS/DELETE_DISH',
  EDIT_DISH: '@MENU_BUILDER_ACTIONS/EDIT_DISH',
  ATTEMPT_TO_DROP_DISH: '@MENU_BUILDER_ACTIONS/ATTEMPT_TO_DROP_DISH',
  DROP_DISH_SUCCESS: '@MENU_BUILDER_ACTIONS/DROP_DISH_SUCCESS',
  EDIT_OPTION: '@MENU_BUILDER_ACTIONS/EDIT_OPTION',
  ATTEMPT_TO_CREATE_OPTION: '@MENU_BUILDER_ACTIONS/ATTEMPT_TO_CREATE_OPTION',
  ATTEMPT_TO_REMOVE_OPTION: '@MENU_BUILDER_ACTIONS/ATTEMPT_TO_REMOVE_OPTION',
  ATTEMPT_TO_UPLOAD_IMAGE: '@MENU_BUILDER_ACTIONS/ATTEMPT_TO_UPLOAD_IMAGE',
  CREATE_MENU_FROM_PDF: '@MENU_BUILDER_ACTIONS/CREATE_MENU_FROM_PDF',
  EDIT_MODIFIER_GROUP: '@MENU_BUILDER_ACTIONS/EDIT_MODIFIER_GROUP',
  EDIT_ITEMS_ORDER: '@MENU_BUILDER_ACTIONS/EDIT_ITEMS_ORDER',
  EDIT_GROUPS_ORDER: '@MENU_BUILDER_ACTIONS/EDIT_GROUPS_ORDER',
  EDIT_MODIFIER_GROUP_SUCCESS: '@MENU_BUILDER_ACTIONS/EDIT_MODIFIER_GROUP_SUCCESS',
  ATTEMPT_TO_REMOVE_IMAGE: '@MENU_BUILDER_ACTIONS/ATTEMPT_TO_REMOVE_IMAGE'
};

export const attemptToCreateSection = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.ATTEMPT_TO_CREATE_SECTION,
  payload
});

export const attemptToCreateDish = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.ATTEMPT_TO_CREATE_DISH,
  payload
});

export const attemptToDropDishToOtherSection = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.ATTEMPT_TO_DROP_DISH,
  payload
});

export const dropDishToOtherSectionSuccess = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.DROP_DISH_SUCCESS,
  payload
});

export const attemptToCreateOption = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.ATTEMPT_TO_CREATE_OPTION,
  payload
});

export const attemptToRemoveOption = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.ATTEMPT_TO_REMOVE_OPTION,
  payload
});

export const attemptToUploadImage = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.ATTEMPT_TO_UPLOAD_IMAGE,
  payload
});

export const attemptToRemoveImage = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.ATTEMPT_TO_REMOVE_IMAGE,
  payload
});

export const createSectionSuccess = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.SET_CREATE_SECTION_SUCCEED,
  payload
});

export const setCreateSectionFailure = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.SET_CREATE_SECTION_FAILURE,
  payload
});

export const attemptToRemoveSection = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.DELETE_SECTION,
  payload
});

export const attemptToRemoveDish = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.DELETE_DISH,
  payload
});

export const attemptToEditSection = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_SECTION,
  payload
});

export const attemptToEditSectionOrder = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_SECTION_ORDER,
  payload
});

export const attemptToEditDishOrder = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_DISH_ORDER,
  payload
});

export const attemptToEditDish = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_DISH,
  payload
});

export const attemptToEditOption = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_OPTION,
  payload
});

export const attemptToEditModifierGroup = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_MODIFIER_GROUP,
  payload
});

export const editModifierGroupSuccess = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_MODIFIER_GROUP_SUCCESS,
  payload
});

export const attemptToCreateMenuFromPdf = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.CREATE_MENU_FROM_PDF,
  payload
});

export const attemptToEditItemsOrder = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_ITEMS_ORDER,
  payload
});

export const attemptToEditGroupsOrder = (payload: Object): BaseAction => ({
  type: MENU_BUILDER_ACTIONS.EDIT_GROUPS_ORDER,
  payload
});
