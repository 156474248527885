import io from "socket.io-client";
import { MENUSYNC_API_CONFIG } from "./config";
import TOAST from './modules/toastManager';

const SOCKET_URL = `${MENUSYNC_API_CONFIG.URL}ordering`;
console.log('SOCKET_URL-----', SOCKET_URL)

export const socket = io(SOCKET_URL, { transports: ['websocket'], upgrade: false });

export const connectSocket = (user_id) => {
  console.log('user_id------', user_id)
  if (socket.connected) {
    console.log('SOCKET is ALREADY CONNECTED', socket.id)
    socket.emit("attemp_to_connect", { connected: true })
  } else {
    socket.on("connect", () => {
      console.log('SOCKET CONNECTED------', socket.id); // x8WIv7-mJelg7on_ALbx
    });

  }
  socket.emit("menuJoin", { id: user_id });
  socket.on("disconnect", () => {
    console.log('SOCKET DISCONNECTED------', socket.id); // undefined
  });

  socket.on("reconnect", () => {
    console.log('ATTEMPT to RECONNECT', socket.id)
  })
  socket.on("pos_menu_sync", (data) => {
    console.log('SOCKET pos_menu_sync------', data); // data
  });
  socket.on("menuJoin", () => {
    console.log('SOCKET menuJoin------');
  });
  // socket.on("test-method",(data)=>{
  //   console.log('SOCKET test-method------',data);

  // })
  socket.on("response", (data) => {
    console.log('SOCKET test-method------', data);
    if (data.data && data.method_name == "pos_menu_sync") {
      TOAST.success(
        `${data.data.menu_name} Menu has been synced!`
      );
    }

  })
}


