// flow
import { takeLatest, put } from "redux-saga/effects";

import TOAST from "../../modules/toastManager";
import { get, put as sendPut, post } from "../../modules/api/request";
import {
  setUserSuccess,
  setUserFailure,
  attemptToGetUser,
  USER_ACTIONS,
} from "../actions/user";
import sagaErrorHandler from "../../utils/sagaErrorHandler";
import { connectSocket } from "../../Socket";

function* getUser({ payload }) {
  try {
    console.log("iddddd", payload);
    const data = yield get(`v1/member/${payload.id}`);

    localStorage.setItem("LoginId", payload.id);
    connectSocket(payload.id)
    
    yield put(setUserSuccess(data));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setUserFailure(error));
  }
}

function* changePassword({ payload }) {
  const { password, id, token } = payload;
  try {
    yield post(`v1/member/${id}/update-password/`, {
      password,
      ...(token && { token }),
    });
    if (token) window.location.href = "/";
    TOAST.success("Password was successfuly updated");
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setUserFailure(error));
  }
}

function* addImg({ payload }) {
  const { img, userId } = payload;
  try {
    if (img) {
      const formData = new FormData();
      formData.append("image", img);
      yield post(`v1/member/${userId}/image/`, formData, false);
    } else {
      yield post(`v1/member/${userId}/image/`);
    }
    yield put(attemptToGetUser({ id: userId }));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
  }
}

function* editUser({ payload }) {
  const { id, full_name, email } = payload;

  try {
    yield sendPut(`v1/member/${id}`, { full_name, email });
    yield put(attemptToGetUser({ id }));
    TOAST.success("Personal information was successfuly updated");
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setUserFailure(error));
  }
}

function* signInSaga<T>(): Iterable<T> {
  yield takeLatest(USER_ACTIONS.ATTEMPT_TO_GET_USER, getUser);
  yield takeLatest(USER_ACTIONS.ATTEMPT_TO_ADD_IMG, addImg);
  yield takeLatest(USER_ACTIONS.ATTEMPT_TO_EDIT_USER, editUser);
  yield takeLatest(USER_ACTIONS.NEW_PASSWORD, changePassword);
}

export default signInSaga;
