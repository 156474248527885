// @flow
import React from 'react';
import './styles.scss';

type Props = {
  className?: string
}

const Loader = ({ className = '' }: Props) => (
  <div className={`app-loader ${className}`}>
    <svg viewBox="0 0 50 50">
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="3.6"
      />
    </svg>
  </div>
);

export default Loader;
