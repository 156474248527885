/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';

import { apiConfig } from '../../config';
import redirectToHomePage from '../../utils/redirectToHomePage';

const { API_URL } = apiConfig;

const OPTIONS = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN
  },
  withCredentials: process.env.REACT_APP_NODE_ENV === 'production'
};

/**
 * Sends request
 * @param {String} path - path
 * @param {Boolean} withAuth - path
 * @param {Boolean} origin - path
 * @returns {Promise} - request, resolved value is contains response.data
 */
export const request = (method, path, withAuth = true, origin = true, options = {}) => {
  const requestOptions = { ...options, ...OPTIONS };
  if (!withAuth) {
    requestOptions.withCredentials = false;
  }

  if (options.headers) {
    requestOptions.headers = { ...requestOptions.headers, ...options.headers };
  }

  requestOptions.url = `${origin ? API_URL : ''}${path}`;
  requestOptions.method = method;

  return new Promise((resolve, reject) => {
    if (!document.cookie.includes('csrftoken') && requestOptions.withCredentials) {
      localStorage.clear();
      redirectToHomePage();
    }
    axios(requestOptions)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        // for timeout error do dispatch(showNetworkError(true));
        if (error.message === 'Network Error') {
          // dispatch(showNetworkError(true));
          // eslint-disable-next-line no-console
          console.error('No Internet in your browser or back-end server is disconnected');
        }
        if ((error.response && error.response.status === 401) || (error.response && error.response.status === 403)) {
          localStorage.clear();
          redirectToHomePage();
        }
        reject(error);
      });
  });
};

/**
 * Sends get request
 * @param {String} path - fetch url path
 * @param {Object | null} params - request query params
 * @param {Boolean} withAuth - set bearer token if exists
 * @param {Boolean} origin - add API_URL to url
 * @returns {Promise} - get request
 */
export const get = (path, params = null, withAuth = true, origin = true) =>
  request('GET', path, withAuth, origin, { params });

/**
 * Sends post request
 * @param {String} path - fetch url path
 * @param {Object} data - request body
 * @param {Boolean} withAuth - set bearer token if exists
 * @param {Object | Null} configs - request options configs
 * @returns {Promise} - post request
 */
export const post = (path, data, withAuth = true, origin = true, configs = null) =>
  request('POST', path, withAuth, origin, { ...configs, data });

/**
 * Send delete request
 * @param {String} path - fetch url path
 * @param {Object} data - request body
 * @param {Object | null} params - request query params
 * @param {Boolean} withAuth - set bearer token if exists
 * @returns {Promise} - post request
 */
export const sendDelete = (path, data, params = null, withAuth = true) =>
  request('DELETE', path, withAuth, true, { data, params });

/**
 * Sends patch request
 * @param {String} path - fetch url path
 * @param {Object} data - request body
 * @param {Object | null} params - request query params
 * @param {Boolean} withAuth - set bearer token if exists
 * @returns {Promise} - post request
 */
export const patch = (path, data, params = null, withAuth = true) =>
  request('PATCH', path, withAuth, true, { data, params });

/**
 * Sends put request
 * @param {String} path - fetch url path
 * @param {Object} data - request body
 * @param {Object | null} params - request query params
 * @param {Boolean} withAuth - set bearer token if exists
 * @returns {Promise} - post request
 */
export const put = (path, data, params = null, withAuth = true) =>
  request('PUT', path, withAuth, true, { data, params });
