// flow
import { takeLatest, put } from 'redux-saga/effects';

import TOAST from '../../modules/toastManager';
import { get } from '../../modules/api/request';
import {
  MENUS_ACTIONS, setGetMenuSectionsSuccess, setGetMenuSectionsFailure
} from '../actions/sections';
import sagaErrorHandler from '../../utils/sagaErrorHandler';

function* getMenuSections({ payload }) {
  const { id } = payload;
  try {
    const data = yield get(`v1/web_menu/${id}`);
    yield put(setGetMenuSectionsSuccess(data));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenuSectionsFailure(error));
  }
}

function* getOrderingMenuSections({ payload }) {
  const { id } = payload;
  try {
    const data = yield get(`v1/ordering_menu/${id}`);
    const sections = data.sections || [];
    sections.unshift({
      id: 0,
      synthetic: true,
      name: 'Synced Dishes',
      section_dishes: data.dishes || [],
      sort_order: -1
    });
    data.sections = sections;
    yield put(setGetMenuSectionsSuccess(data));
  } catch (err) {
    const error = sagaErrorHandler(err);
    TOAST.error(error);
    yield put(setGetMenuSectionsFailure(error));
  }
}

function* signInSaga<T>(): Iterable<T> {
  yield takeLatest(MENUS_ACTIONS.ATTEMPT_TO_GET_ORDER_SECTIONS, getOrderingMenuSections);
  yield takeLatest(MENUS_ACTIONS.ATTEMPT_TO_GET_MENU_SECTIONS, getMenuSections);
}

export default signInSaga;
