import XLSX from 'xlsx';

const exportToToastXLSX = (data: { menuBuild: any[], modifierBuild: any[] } = {}, params = {
  MenuInternalName: 'N/A'
}) => {
  const workbook = XLSX.utils.book_new();
  const SHEET_NAMES = {
    MENU: 'Menu Build',
    MODIFIER: 'Modifier Build'
  };
  const menuSheetData = [
    [params.menuName],
    [
      'MENU ITEMS HERE',
      'BASE PRICE $',
      'DESCRIPTION (Optional)',
      'MENU GROUP NAME',
      'ASSIGN MODIFIER GROUPS (Optional) Using the Modifier Groups Created in Modifier Build sheet',
      'ASSIGN ADDITIONAL MODIFIER GROUPS (Optional)',
      'ASSIGN ADDITIONAL MODIFIER GROUPS (Optional)',
      'ASSIGN ADDITIONAL MODIFIER GROUPS (Optional)'
    ]
  ];
  const modifierSheetData = [
    ['CREATE MODIFIER GROUPS'],
    [
      'MODIFIER GROUP NAME',
      'MODIFIER OPTIONS',
      'UPCHARGE $\n(Optional)',
      'IS MODIFIER GROUP REQUIRED? Indicate if they have to make a selection (Optional)',
      'MINIMUM # OF MODS How many mods the server can select',
      'MAXIMUM # OF MODS\nHow many mods the server can select'
    ]
  ];
  for (let i: number = 0; i < data.menuBuild.length; i++) {
    const { items, text } = data.menuBuild[i];
    const currentRow = [];
    for (let j: number = 0; j < items.length; j++) {
      const { description, price, text: dishName } = items[j];
      currentRow.push([dishName, price, description, text]);
    }
    menuSheetData.push(...currentRow);
  }

  for (let i: number = 0; i < data.modifierBuild.length; i++) {
    const { name: dishOptionName, price: dishOptionPrice } = data.modifierBuild[i];
    modifierSheetData.push(['', dishOptionName, dishOptionPrice]);
  }
  workbook.SheetNames.push(SHEET_NAMES.MENU);
  workbook.SheetNames.push(SHEET_NAMES.MODIFIER);
  workbook.Sheets[SHEET_NAMES.MENU] = XLSX.utils.aoa_to_sheet(menuSheetData);
  workbook.Sheets[SHEET_NAMES.MODIFIER] = XLSX.utils.aoa_to_sheet(modifierSheetData);
  XLSX.writeFile(workbook, `${params.MenuInternalName}.TOAST.xlsx`);
};

export default exportToToastXLSX;
